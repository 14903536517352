import { useState, useEffect, useRef } from "react";
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import "./VerifyEmail.css";
import hsccImg from "../../assests/images/logo.png";
import signupImg from "../../assests/images/signupImg.jpg";
import { toast } from "react-toastify";

const api = process.env.REACT_APP_END_POINT;

const VerifyEmail = ({ regFormData }) => {
  const navigate = useNavigate();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  let userEmail =
    userDetails && userDetails.userEmail ? userDetails.userEmail : "";
  let isEmailFieldDisabled = userDetails ? true : false;
  const intialValues = {
    email: userEmail,
    otp: "",
  };
  const [verifyEmailData, setVerifyEmailData] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVerifyEmailData({ ...verifyEmailData, [name]: value });
    setFormErrors("");
  };

  const formValidate = (val) => {
    const error = { success: true };
    if (!val.otp) {
      error.otp = "Please Enter OTP";
      error.success = false;
    }
    if (!val.email) {
      error.email = "Email is required";
      error.success = false;
    }
    return error;
  };
  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 120);
    return {
      total,
      minutes,
      seconds,
    };
  }
  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }
  function clearTimer(endtime) {
    setTimer("01:59");
    if (intervalRef.current) clearInterval(intervalRef.current);
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }
  function getDeadlineTime() {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  }

  useEffect(() => {
    clearTimer(getDeadlineTime());
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const validate = formValidate(verifyEmailData);
    setFormErrors(validate);
    let sessionToken = localStorage.getItem("sessionToken");
    if (validate.success) {
      try {
        let verifyEmail = await fetch(`${api}accounts/activate`, {
          method: "PATCH",
          body: JSON.stringify({
            token: sessionToken,
            otp: parseInt(verifyEmailData.otp),
          }),
          headers: {
            Accept: "accept: application/json",
            "Content-Type": "application/json",
          },
        });
        let status = verifyEmail.status;
        verifyEmail = await verifyEmail.json();
        if (status >= 400) throw new Error(verifyEmail.message);
        navigate("/login");
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const resendOTP = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    try {
      let result = await fetch(`${api}accounts/resend-otp`, {
        method: "POST",
        body: JSON.stringify({
          email: verifyEmailData.email,
        }),
        headers: {
          Accept: "accept: application/json",
          "Content-Type": "application/json",
        },
      });
      let status = result.status;
      result = await result.json();
      if (status >= 400) throw new Error(result.message);
      localStorage.setItem("sessionToken", result?.sessionToken);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="d-flex verify-email-main-container shadow">
        <Container className="d-flex justify-content-center align-items-center">
          <div className="verify-email-main-content">
            <div className="row shadow">
              <div className="col-6 p-0">
                <div className="para-content">
                  <div>
                    <img className="signup-page-img" alt="" src={hsccImg} />
                  </div>

                  <div className="text-data">
                    <ul>
                      <h3 className="text-heading">
                        Welcome To The HSCC Career{" "}
                      </h3>
                      <h4 className="text-subheading"> Why Signup?</h4>
                      <li className="text-data">
                        Find the right job and grow your career
                      </li>
                      <li className="text-data">
                        Build your profile and let recruiters find you
                      </li>
                      <li className="text-data">
                        Manage your profile and apply
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img
                      className="signup-page-data-img"
                      src={signupImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0">
                <Card className=" main-card">
                  <Card.Body>
                    <div className="mb-3 mt-md-4">
                      <h2 className="fw-bold mb-2 text-center text-uppercase text-primary">
                        Verify Email
                      </h2>
                      <div className="mb-3 d-flex justify-content-center align-items-center">
                        <Form onSubmit={HandleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="text-center">
                              Email address
                            </Form.Label>
                            <Form.Control
                              className="reg-form-input-field"
                              type="email"
                              placeholder="Enter email"
                              autoComplete="off"
                              name="email"
                              value={verifyEmailData.email}
                              onChange={handleChange}
                              disabled={isEmailFieldDisabled}
                            />
                            <div className="required-field">
                              {formErrors.email}
                            </div>
                          </Form.Group>
                          <Button
                            // className="ve-submit-button"
                            variant="primary pull-right mb-3"
                            type="button"
                            onClick={resendOTP}
                          >
                            Resend OTP
                          </Button>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>OTP</Form.Label>
                            <Form.Control
                              className="reg-form-input-field"
                              type="text"
                              placeholder="Otp"
                              autoComplete="off"
                              name="otp"
                              value={verifyEmailData.otp}
                              onChange={handleChange}
                            />
                            <div className="required-field">
                              {formErrors.otp}
                            </div>
                          </Form.Group>
                          <Button
                            // className="ve-submit-button"
                            variant="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <div className="d-flex justify-content-center form-text fw-bold">
                            Time Left<span>{timer}</span>
                          </div>
                          <div className="mt-3">
                            <p className="mb-0  text-center">
                              Don't have an account??{" "}
                              <a
                                className="text-primary fw-bold c-p"
                                onClick={() => navigate("/registration")}
                              >
                                Create account
                              </a>
                            </p>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default VerifyEmail;
