import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { get } from "./services/api";
import { useNavigate } from "react-router";
import { enumPost } from "./services/helper";

const api = process.env.REACT_APP_END_POINT;

const Dashboard = () => {
  const [getApplicationList, setApplicationList] = useState([]);
  const navigate = useNavigate();

  async function getData() {
    try {
      let response = await get(`${api}application/get`);
      response = await response.json();
      setApplicationList(response.data);
      // if (!response?.data?.length || response.data.length === 0) {
      //   navigate("/application");
      // }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    getData();
    return () => {
      abortController.abort();
    };
  }, []);

  const handleCompleteForm = (
    applicationId,
    paymentStatus,
    addId = "HSCCRECT20241"
  ) => {
    localStorage.setItem("addId", addId);
    if (paymentStatus !== "completed") {
      if (applicationId) {
        localStorage.setItem("applicationId", applicationId);
      }
      navigate("/application?addId=" + addId);
    } else {
      toast.success("Your application is sent for the review");
      navigate("/users-form/" + applicationId);
    }
  };

  const applyNew = (addId) => {
    localStorage.removeItem("applicationId");
    localStorage.setItem("addId", addId);
    navigate("/application?addId=" + addId);
  };

  return (
    <>
      <div className="services pb-5">
        <div className="container">
          <div className="pt-5">
            <h2 className="vc_custom_heading ico_header">Application list</h2>
            <hr />
            <div className="row">
              <div className="col-md-12">
                <div className="investor-box">
                <h2>
                   Registration has been closed
                  </h2>
                  <h2>
                    Closing Date for HSCC/RECT/2024/1 is 05:00 pm on 29.04.2024
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              {getApplicationList.map((element, index) => (
                <div className="col-md-3" key={index}>
                  <div
                    className="investor-box c-p"
                    onClick={() =>
                      handleCompleteForm(
                        element._id,
                        element.paymentStatus,
                        element.addId
                      )
                    }
                  >
                    <h2>Level: {element.level}</h2>
                    <h2>Post: {enumPost[element.postApplied]}</h2>
                    <h2>
                      Status:{" "}
                      {element.paymentStatus !== "completed"
                        ? "Payment Pending"
                        : "Completed"}
                    </h2>
                    <div className="flip-view">
                      {element.paymentStatus !== "completed"
                        ? "Complete Now"
                        : "Application is submitted for the review"}
                      <i className="fas fa-chevron-circle-right"></i>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="col-md-3">
                <div
                  className="investor-box c-p"
                  onClick={() => applyNew("HSCCRECT20241")}
                >
                  <h2>Click here to Apply</h2>
                  <h2>Advt No.:- HSCC/RECT/2024/1</h2>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
