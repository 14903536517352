/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify";
import "../../assests/application-form.css";
import { get } from "../services/api";
import OtherInfo from "./OtherInfo";
import PersonalInfo from "./PersonalInfo";
import Qualification from "./Qualification";
import WorkInfo from "./WorkInfo";
import { useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
const api = process.env.REACT_APP_END_POINT;
const env = process.env.REACT_APP_ENV;

// https://codepen.io/piyushpd139/pen/PooPByb
const Application = () => {
  const navigate = useNavigate();

  const [step, setCount] = useState(1);
  const [applicationData, setApplicationData] = useState(false);
  const addIds = ["HSCCRECT20241", "HSCCRECT20232"];
  const [searchParams] = useSearchParams();

  let addId = searchParams.get("addId");
  if (addIds.includes(addId)) {
    localStorage.setItem("addId", addId);
  } else {
    navigate("/dashboard");
  }
  let applicationId = localStorage.getItem("applicationId");
  useMemo(() => {
    const acontroler = new AbortController();
    async function getData() {
      try {
        let data = {};
        if (applicationId) {
          let response = await get(`${api}application/get/${applicationId}`);
          const status = response.status;
          if (status >= 400) throw new Error("Somthing went wrong");
          response = await response.json();
          data = response.data;
          if (data === null) {
            localStorage.removeItem("applicationId");
          }
        }

        let userData = localStorage.getItem("userData")
          ? localStorage.getItem("userData")
          : {};
        const { isEmailVerified, isInternalId, name, email } = userData;

        setApplicationData({
          ...data,
          isEmailVerified,
          isInternalId,
          name,
          email,
        });
      } catch (error) {
        toast.error(error.message);
      }
    }
    getData();
    return () => {
      acontroler.abort();
    };
  }, []);

  const handleNextSteps = () => {
    if (step >= 4) return;
    return setCount(step + 1);
  };

  const handleBackSteps = () => {
    if (step <= 1) return;
    return setCount(step - 1);
  };
  return (
    <>
      <section className="signup-step-container">
        <div className="container shadow bg-white rounded">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="wizard">
                <div className="wizard-inner">
                  <div className="connecting-line"></div>
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      role="presentation"
                      className={step === 1 ? "active" : "disabled"}
                    >
                      <a
                        href="#step1"
                        data-toggle="tab"
                        aria-controls="step1"
                        role="tab"
                        aria-expanded="true"
                      >
                        <span className="round-tab">1 </span>{" "}
                        <i>Personal Info</i>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={step === 2 ? "active" : "disabled"}
                    >
                      <a
                        href="#step2"
                        data-toggle="tab"
                        aria-controls="step2"
                        role="tab"
                        aria-expanded="false"
                      >
                        <span className="round-tab">2</span>{" "}
                        <i>Qualification</i>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={step === 3 ? "active" : "disabled"}
                    >
                      <a
                        href="#step3"
                        data-toggle="tab"
                        aria-controls="step3"
                        role="tab"
                      >
                        <span className="round-tab">3</span> <i>Work Info</i>
                      </a>
                    </li>
                    <li
                      role="presentation"
                      className={step === 4 ? "active" : "disabled"}
                    >
                      <a
                        href="#step4"
                        data-toggle="tab"
                        aria-controls="step4"
                        role="tab"
                      >
                        <span className="round-tab">4</span> <i>Other Info</i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="main_form">
                  {applicationData && (
                    <PersonalInfo
                      step={step}
                      applicationData={applicationData}
                      handleNextSteps={handleNextSteps}
                      handleBackSteps={handleBackSteps}
                    />
                  )}
                  {applicationData && (
                    <Qualification
                      step={step}
                      applicationData={applicationData}
                      handleNextSteps={handleNextSteps}
                      handleBackSteps={handleBackSteps}
                    />
                  )}
                  {applicationData && (
                    <WorkInfo
                      step={step}
                      applicationData={applicationData}
                      handleNextSteps={handleNextSteps}
                      handleBackSteps={handleBackSteps}
                    />
                  )}
                  {applicationData && (
                    <OtherInfo
                      step={step}
                      applicationData={applicationData}
                      handleNextSteps={handleNextSteps}
                      handleBackSteps={handleBackSteps}
                    />
                  )}

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {env !== "production" ? (
          <button type="button" onClick={handleNextSteps}>
            Test Button
          </button>
        ) : null}
      </section>
    </>
  );
};

export default Application;
