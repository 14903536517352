/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { deleteUpload, fileUpload, patch, post } from "../services/api";
import { ageRelaxation, discipline, level } from "../services/helper";

const api = process.env.REACT_APP_END_POINT;
const baseUrl = process.env.REACT_APP_FILE_BASE_URL;
/* eslint-disable import/no-anonymous-default-export */
export default ({
  applicationData,
  step,
  handleBackSteps,
  handleNextSteps,
}) => {
  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [getAge, setAge] = useState(0);
  const [getDiscipline, setDiscipline] = useState({});
  const [getLevel, setLevel] = useState({});
  // const [passPhotoFile, setPassPhotoFile] = useState();
  // const [communityFile, setCommunityFile] = useState();
  useEffect(() => {
    let userData = localStorage.getItem("userData");
    let addId = localStorage.getItem("addId");
    if (addId) {
      setDiscipline(discipline[addId]);
      setLevel(level[addId]);
    }
    userData = userData ? JSON.parse(userData) : {};
    applicationData.name = userData.name;
    applicationData.email = userData.email;
    applicationData.isInternalId = userData.isInternalId;
    setFormData({ ...applicationData });
  }, []);
  const oneMB = 1000000;
  const halfMB = 500000;
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "text") {
      value = value.toUpperCase();
    }
    if (e.target.type === "date") {
      if (value.length > 10) return;
    }
    if (e.target.type === "checkbox") {
      let isChecked = e.target.checked;
      if (isChecked) {
        formData.permanentAddress = formData.address;
      }
    }

    setFormData({ ...formData, [name]: value });
    // setFormErrors("");
  };

  const handleUploadClick = async (fieldName) => {
    let file = null;
    const docEle = document.getElementById(fieldName);
    file = docEle.files[0];
    if (!file) return;

    if (fieldName === "passPhoto" && file.size > halfMB) {
      toast.error("File size should be less then 1mb");
      docEle.value = "";
      return;
    }
    if (fieldName === "categoryProof" && file.size > oneMB) {
      toast.error("File size should be less then 1mb");
      docEle.value = "";
      return;
    }

    if (fieldName === "ageProof" && file.size > oneMB) {
      toast.error("File size should be less then 1mb");
      docEle.value = "";
      return;
    }

    const data = new FormData();
    data.append(fieldName, file);
    fileUpload(`${api}accounts/upload-file`, data)
      .then((res) => res.json())
      .then((fileUploadResp) => {
        console.log("File Uploaded");
        let filePath = fileUploadResp.filePath;
        setFormData((prev) => {
          return { ...prev, [fieldName]: filePath };
        });
      })

      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteFile = async (filePath, fieldName) => {
    deleteUpload(`${api}accounts/delete-file`, filePath)
      .then((fileDeleteResp) => {
        console.log("File Deleted");
        setFormData((prev) => {
          return { ...prev, [fieldName]: "" };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formValidate = (val) => {
    const phAgeRelaxation = 10;
    const IntAgeRelaxation = 5;
    const error = { success: true };
    if (!val.postApplied) {
      error.postApplied = "Please select a post";
      error.success = false;
    }
    if (!val.level) {
      error.level = "Please select a level to apply";
      error.success = false;
    }
    if (!val.name) {
      error.name = "Name is required";
      error.success = false;
    }
    if (!val.passPhoto) {
      error.passPhoto = "Please upload passport size photo";
      error.success = false;
    }
    if (!val.fathersName) {
      error.fathersName = "Please enter your father's name";
      error.success = false;
    }
    if (!val.dob) {
      error.dob = "Please select your date of birth";
      error.success = false;
    }
    if (!val.category) {
      error.category = "Please select your category";
      error.success = false;
    }
    if (!val.categoryProof && val?.category !== "UR") {
      error.categoryProof = "Please upload category proof document";
      error.success = false;
    }
    if (!val.religion) {
      error.religion = "Please Enter your religion";
      error.success = false;
    }
    if (!val.isMinority) {
      error.isMinority = "Please Select";
      error.success = false;
    }
    if (!val.address) {
      error.address = "Please Enter your full address";
      error.success = false;
    }
    if (!val.mobileNo) {
      error.mobileNo = "Please enter a valid mobile no.";
      error.success = false;
    }

    if (!val.permanentAddress) {
      error.permanentAddress = "Please enter your permanent address";
      error.success = false;
    }

    if (!val.ageProof) {
      error.ageProof = "Please upload your age proof";
      error.success = false;
    }

    if (!val.gender) {
      error.gender = "Please select your gender";
      error.success = false;
    }

    if (!val.isPH) {
      error.isPH = "Please select one";
      error.success = false;
    }

    if (val.isPH === "Yes") {
      if (!val.phType) {
        error.phType = "Pelese write your type of disabilities";
        error.success = false;
      }
      if (!val.phProof) {
        error.phProof = "Please upload a document proof";
        error.success = false;
      }
    }

    if (!val.maritalStatus) {
      error.maritalStatus = "Please select one";
      error.success = false;
    }

    if (!val.isExServiceMan) {
      error.isExServiceMan = "Please select one";
      error.success = false;
    }

    if (val.level && val.category) {
      let category = val.category;

      let age = getAge;
      let relaxation = ageRelaxation[`${val.level}_${category}`];
      if (val.isPH === "Yes") {
        relaxation += phAgeRelaxation;
      }
      if (val.isExServiceMan === "Yes") {
        relaxation = 57;
      }
      if (val.isInternalId === "Yes") {
        relaxation += IntAgeRelaxation;
      }
      if (age > relaxation) {
        error.ageError = `Your age should be less then ${relaxation} for level ${val.level} and your age is ${age}`;
        error.success = false;
      }
      if (age > 60) {
        error.ageError = `Maximum age can not exceed 60 Years and your age is ${age}`;
        error.success = false;
      }
    }
    return error;
  };

  async function HandleSubmit() {
    const validate = formValidate(formData);
    let applicationId = localStorage.getItem("applicationId");
    let addId = localStorage.getItem("addId");
    setFormErrors(validate);
    if (validate.success) {
      const reqBody = {
        postApplied: formData.postApplied,
        level: formData.level,
        passPhoto: formData.passPhoto,
        dob: formData.dob,
        category: formData.category,
        categoryProof: formData.categoryProof,
        religion: formData.religion,
        isMinority: formData.isMinority,
        address: formData.address,
        mobileNo: formData.mobileNo,
        fathersName: formData.fathersName,
        ageProof: formData.ageProof,

        name: formData.name,
        email: formData.email,
        age: getAge || formData.age,
        isPH: formData.isPH,
        phType: formData.phType,
        phProof: formData.phProof,
        permanentAddress: formData.permanentAddress,
        gender: formData.gender,
        maritalStatus: formData.maritalStatus,
        isExServiceMan: formData.isExServiceMan,
        addId: addId,
      };
      let response = "";
      if (applicationId) {
        response = patch(
          `${api}application/personal-details/${applicationId}`,
          reqBody
        );
      } else {
        response = post(`${api}application/personal-details`, reqBody);
      }
      response
        .then((res) => {
          if (res.status === 201) {
            return res.json();
          }
          throw new Error("Something went wrong");
        })
        .then((registerData) => {
          applicationId = registerData?.data?._id;
          if (applicationId) {
            localStorage.setItem("applicationId", applicationId);
            handleNextSteps();
          }
        })
        .catch((e) => {
          console.log(e.status, "in error");
          toast.error(e.message);
        });
    }
  }

  const calculateAge = (value) => {
    if (value) {
      var dob = new Date(value);
      var tillDate = new Date("2024-04-20");
      var month_diff = tillDate.getTime() - dob.getTime();
      var age_dt = new Date(month_diff);
      // var year = age_dt.getUTCFullYear();
      // var age = Math.abs(year - 1970);
      var age =
        age_dt.toISOString().slice(0, 4) -
        1970 +
        "Y " +
        age_dt.getMonth() +
        "M " +
        (age_dt.getDate() - 1) +
        "D";
      setAge(age);
      if (age > 60 && value.length >= 10) {
        setFormErrors({ age: "Age should be less then 60 Years" });
      } else {
        setFormErrors("");
      }
    }
  };

  return (
    <div
      className={`tab-pane ${step === 1 ? "active" : ""}`}
      role="tabpanel"
      id="step1"
    >
      <h4 className="text-center step-head">
        <u>Personal Info</u>
      </h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Post Applied for <span>*</span>
            </label>
            <select
              name="postApplied"
              defaultValue={""}
              value={formData?.postApplied}
              className="form-control"
              id="postApplied"
              onChange={handleChange}
            >
              <option value="">-Select Post Applied for-</option>
              {Object.keys(getDiscipline).map((key, index) => {
                return <option value={key}>{getDiscipline[key]}</option>;
              })}
            </select>
            <div className="required-field">{formErrors.postApplied}</div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              Level <span>*</span>
            </label>
            <select
              name="level"
              defaultValue={""}
              value={formData?.level}
              className="form-control"
              id="level"
              onChange={handleChange}
            >
              <option value="">-Select Level-</option>
              {Object.keys(getLevel).map((key, index) => {
                return <option value={key}>{getLevel[key]}</option>;
              })}
            </select>
            <div className="required-field">{formErrors.level}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Passport Photo <span>*</span>
            </label>
            <input
              accept="image/png, image/jpeg, image/jpg"
              className="form-control"
              type="file"
              name="passPhoto"
              id="passPhoto"
            />
            <div className="required-field">{formErrors.passPhoto}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label></label>
            {formData?.passPhoto ? (
              <div className="card preview">
                <img
                  height={100}
                  width={100}
                  src={`${baseUrl}${formData?.passPhoto}`}
                  alt=""
                />
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    handleDeleteFile(formData.passPhoto, "passPhoto")
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-secondary btn-upload form-control"
                onClick={() => handleUploadClick("passPhoto")}
              >
                Upload
              </button>
            )}
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>
              Email <span>*</span>
            </label>
            <input
              className="form-control"
              name="email"
              placeholder=""
              value={formData?.email}
              disabled={true}
            />
            <div className="required-field">{formErrors.email}</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Full Name <span>*</span>
            </label>
            <input
              className="form-control"
              name="name"
              placeholder=""
              value={formData?.name}
              onChange={handleChange}
            />
            <div className="required-field">{formErrors.name}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>
              Father's Name <span>*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="fathersName"
              placeholder=""
              value={formData?.fathersName}
              onChange={handleChange}
            />
            <div className="required-field">{formErrors.fathersName}</div>
          </div>
        </div>

        <div className="col-md-2">
          <div className="form-group">
            <label>
              Date of Birth <span>*</span>
            </label>
            <input
              className="form-control"
              type="date"
              name="dob"
              value={formData?.dob}
              placeholder=""
              maxLength={8}
              max="2024-04-20"
              onChange={(e) => {
                handleChange(e);
                calculateAge(e.target.value);
              }}
            />
            <div className="required-field">{formErrors.dob}</div>
            <div className="required-field">{formErrors.ageError}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <label>Your age</label>
            <input
              className="form-control"
              type="age"
              name="age"
              value={getAge || formData?.age}
              placeholder=""
              disabled={true}
            />
            <div className="required-field">{formErrors.age}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>
              Upload Age Proof <span>*</span>
            </label>
            <input
              accept="application/pdf, image/png, image/jpeg, image/jpg"
              className="form-control"
              type="file"
              name="ageProof"
              id="ageProof"
            />
            <div className="required-field">{formErrors.ageProof}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            {formData?.ageProof ? (
              <div className="card preview">
                <object
                  data={`${baseUrl}${formData?.ageProof}`}
                  type="application/pdf"
                  aria-label="aa proof pdf"
                ></object>

                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    handleDeleteFile(formData.ageProof, "ageProof")
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="form-control btn btn-secondary btn-upload"
                onClick={() => handleUploadClick("ageProof")}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Category <span>*</span>
            </label>
            <select
              name="category"
              defaultValue={""}
              value={formData?.category}
              className="form-control"
              id="category"
              onChange={handleChange}
            >
              <option value="">-Select a Category-</option>
              <option value="SC">SC</option>
              <option value="ST">ST</option>
              <option value="OBC">OBC</option>
              <option value="EWS">EWS</option>
              <option value="UR">Un-Reserved</option>
            </select>
            <div className="required-field">{formErrors.category}</div>
          </div>
        </div>
        {formData?.category !== "UR" ? (
          <>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Upload Category Proof <span>*</span>
                </label>
                <input
                  accept="application/pdf"
                  className="form-control"
                  type="file"
                  name="categoryProof"
                  id="categoryProof"
                />
                <div className="required-field">{formErrors.categoryProof}</div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                {formData?.categoryProof ? (
                  <div className="card preview">
                    <object
                      data={`${baseUrl}${formData?.categoryProof}`}
                      type="application/pdf"
                      aria-label="category proof pdf"
                    ></object>

                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() =>
                        handleDeleteFile(
                          formData.categoryProof,
                          "categoryProof"
                        )
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="form-control btn btn-secondary btn-upload"
                    onClick={() => handleUploadClick("categoryProof")}
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Are you Physical Handicap?<span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isPH"
                  id="isPH1"
                  onChange={handleChange}
                  value={"Yes"}
                  checked={formData?.isPH === "Yes"}
                />
                <label className="form-check-label" htmlFor="isPH1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isPH"
                  id="isPH2"
                  onChange={handleChange}
                  value={"No"}
                  checked={formData?.isPH === "No"}
                />
                <label className="form-check-label" htmlFor="isPH2">
                  No
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.isPH}</div>
          </div>
        </div>
        {formData?.isPH === "Yes" ? (
          <>
            <div className="col-md-4">
              <div className="form-group">
                <label>Physical Handicap Type</label>
                <input
                  className="form-control"
                  type="phType"
                  name="phType"
                  value={formData?.phType}
                  onChange={handleChange}
                  placeholder=""
                />
                <div className="required-field">{formErrors.phType}</div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Physical Handicap Proof <span>*</span>
                </label>
                <input
                  accept="application/pdf"
                  className="form-control"
                  type="file"
                  name="phProof"
                  id="phProof"
                />
                <div className="required-field">{formErrors.phProof}</div>
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-group">
                {formData?.phProof ? (
                  <div className="card preview">
                    <object
                      data={`${baseUrl}${formData?.phProof}`}
                      type="application/pdf"
                      aria-label="category proof pdf"
                    ></object>

                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() =>
                        handleDeleteFile(formData.phProof, "phProof")
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="form-control btn btn-secondary btn-upload"
                    onClick={() => handleUploadClick("phProof")}
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Religion <span>*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="religion"
              placeholder=""
              value={formData?.religion}
              onChange={handleChange}
            />
            <div className="required-field">{formErrors.religion}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>
              Whether belong to minority?<span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isMinority"
                  id="isMinority1"
                  onChange={handleChange}
                  value={"Yes"}
                  checked={formData?.isMinority === "Yes"}
                />
                <label className="form-check-label" htmlFor="isMinority1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isMinority"
                  id="isMinority2"
                  onChange={handleChange}
                  value={"No"}
                  checked={formData?.isMinority === "No"}
                />
                <label className="form-check-label" htmlFor="isMinority2">
                  No
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.isMinority}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Are You Ex-Serviceman?<span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isExServiceMan"
                  id="isExServiceMan1"
                  onChange={handleChange}
                  value={"Yes"}
                  checked={formData?.isExServiceMan === "Yes"}
                />
                <label className="form-check-label" htmlFor="isExServiceMan1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isExServiceMan"
                  id="isExServiceMan2"
                  onChange={handleChange}
                  value={"No"}
                  checked={formData?.isExServiceMan === "No"}
                />
                <label className="form-check-label" htmlFor="isExServiceMan2">
                  No
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.isExServiceMan}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>
              Gender?<span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="gender1"
                  onChange={handleChange}
                  value={"Male"}
                  checked={formData?.gender === "Male"}
                />
                <label className="form-check-label" htmlFor="gender1">
                  Male
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  id="gender2"
                  onChange={handleChange}
                  value={"Female"}
                  checked={formData?.gender === "Female"}
                />
                <label className="form-check-label" htmlFor="gender2">
                  Female
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.gender}</div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>
              Marital Status <span>*</span>
            </label>
            <select
              name="maritalStatus"
              defaultValue={""}
              value={formData?.maritalStatus}
              className="form-control"
              id="maritalStatus"
              onChange={handleChange}
            >
              <option value="">-Select Marital Status-</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
              <option value="Widowed">Widowed</option>
            </select>
            <div className="required-field">{formErrors.maritalStatus}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Correspondence Address <span>*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="address"
              placeholder=""
              value={formData?.address}
              onChange={handleChange}
            />
            <div className="required-field">{formErrors.address}</div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>
              Mobile <span>*</span>
            </label>
            <input
              className="form-control"
              type="number"
              name="mobileNo"
              placeholder=""
              value={formData?.mobileNo}
              onChange={handleChange}
            />
            <div className="required-field">{formErrors.mobileNo}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Permanent Address<span>*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="permanentAddress"
              placeholder=""
              value={formData?.permanentAddress}
              onChange={handleChange}
            />
            <div className="required-field">{formErrors.permanentAddress}</div>
          </div>
        </div>
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              checked={formData?.address === formData?.permanentAddress}
              type="checkbox"
              onChange={handleChange}
              id="gridCheck"
            />
            <label className="form-check-label" htmlFor="gridCheck">
              Same as above
            </label>
          </div>
        </div>
      </div>
      <ul className="list-inline pull-right">
        <li>
          <button
            onClick={handleBackSteps}
            type="button"
            className="default-btn prev-step"
          >
            Back
          </button>
        </li>
        <li>
          <button
            onClick={HandleSubmit}
            type="button"
            className="default-btn next-step m-3"
          >
            Save & Next
          </button>
        </li>
      </ul>
    </div>
  );
};
