import React from 'react'
import "./AboutUs.css";

export const AboutUs = () => {
  return (
    <>
      <div className="container pt-3">
        <h4 className='aboutus-title mt-2'>About Us</h4>
        <div className='row mt-4 mb-0'>
          <div className='col-12'>
            <p>
              Set up in 1983 with an authorised capital of Rs. 20 million, HSCC is one of the few organisation in South East Asia, rendering comprehensive range of professional consultancy services in health-care and other social sectors, in India and abroad.
              HSCC's services have been utilized by various organisation, both in Public and Private Sectors, Central Government Department, State Governments as also international agencies like the World Bank, WHO, among others for their projects in India and abroad.
            </p></div>
        </div>
        <p>HSCC is a pluri-disciplinary organisation with experienced professionals (i.e. health planners and economists, doctors, biomedical engineers, computer experts, pharmacists, architects and public health engineers etc) on rolls and a network of consultants specialized in various activities associated with health systems. Besides, it has institutional arrangements with various research laboratories/speciality hospitals. Further, to render high-quality professional services, it draws on resources from other agencies/institutions to supplement and complement its in-house capacities and capabilities for implementation of projects, where necessary.
        </p>
        <p>
          Main activities of the Company address themselves to all levels of the health system pyramid and encompass conceptual studies, health-care facilities design, project management, procurement and supply, logistics and installation, commissioning and skill enhancement through training and retraining.</p>
        <p>
          Since hospitals represent a substantial portion of any health system, a significant part of HSCC's activities is devoted to design and implementation of new hospitals & medical colleges teaching institutions and/or rehabilitation/up-gradation of existing institutions.</p>

        <p>HSCC, an ISO: 9001 : 2015 accredited Company, adopts an integrated approach to projects, drawing on its pool of expertise to provide the best combination to evolve client-specific, cost-effective innovation solutions. A wide range of services that are provided relate to components of health systems from conceptualization through procurement of equipment/drugs, to complex projects involving design and implementation.</p>
        <h4 className='aboutus-sub-title'>Vision:</h4>
        <p>To be a leading consulting company providing value-added, innovative and integrated services for enhancing healthcare in india and overseas, leveraging its core competance in other infrastructure projects and providing an invigorating and enabling work environment to its professional employees.</p>
        <h4 className='aboutus-sub-title'>Corporate Mission:</h4>
        <p >Providing Comprehensive, concept to commissioning, project planning, architectural, engineering, project management, procurement and related consulting services for development of buildings and infrastructure for healthcare and other purposes in india overseas.</p>
        <h4 className='aboutus-sub-title'>Corporate Quality Policy:
        </h4>
        <p>To maintain leadership and customer confidence by providing continually improving quality consultancy services in the Healthcare and other Social Sectors.
        </p>
      </div>
    </>
  )
}
