import React from "react";
import "./TermsAndConditions.css";

export const TermsAndConditions = () => {
  return (
    <>
      <div className="container pt-3">
        <h4 className="tac-title mt-2">Terms And Conditions</h4>
        <ol>
          <p>
            {" "}
            1. Candidates should read the complete advertisement carefully &amp;
            ensure that he/she fulfils the eligibility criteria for the post
            advertised in all respects.
          </p>

          <p>
            {" "}
            2. All the Essential Qualification must be full time and be
            recognized from AICTE/UGC/State Technical Board/Any other
            appropriate body.
          </p>

          <p>
            {" "}
            3. If a candidate is claiming a particular qualification as
            equivalent qualification as per the requirement of advertisement,
            then the candidate is required to produce order/ letter in this
            regard issued by the Competent Authority indicating the Authority
            (with number and date) under which it has been so treated otherwise
            the candidature/Online Application is liable to be rejected.
          </p>

          <p>
            {" "}
            4. Candidates are requested to give specific, correct, full
            information. All original certificates/ documents in support of
            information furnished in the application form are to be produced at
            the time of verification of documents, failing which the candidate
            will be disqualified. Candidature is liable to be rejected at any
            stage of recruitment process or after recruitment or joining, if any
            information provided by the candidate is found to be false or is not
            found in conformity with eligibility criteria mentioned in the
            advertisement.
          </p>

          <p>
            {" "}
            5. Incomplete application will be summarily rejected. Any request to
            add additional information in application form after the application
            has been submitted shall not be entertained in any condition.
          </p>

          <p>
            {" "}
            6. Persons with benchmark disabilities can also apply for any of the
            above post in accordance with Government of India guidelines in
            force. Disability of applicants applying against the post reserved
            for disabled persons should not be less than 40%.
          </p>

          <p>
            {" "}
            7. The upper age limit indicated is for Unreserved category
            candidates. Age relaxation for SC/ST/OBC (NCL)/ Person with
            Disabilities (PWD) will be as per Government guidelines in this
            regard. SC/ST/OBC (NCL)/PWD candidates are required to upload
            scanned copy of their caste/ disability certificate (as applicable)
            along with the application form. Age relaxation for Ex-serviceman
            will be service rendered in the Armed forces plus 03 years.
          </p>

          <p>
            {" "}
            8. Internal candidates HSCC (India) Limited will be given age
            relaxation of five years provided they have at least three years of
            service left before superannuation.
          </p>

          <p>
            {" "}
            9. Internal candidates working on contractual establishment will be
            entitled for age relaxation of 5 years or the period served in the
            organization, whichever is higher, subject to fulfilment of the
            other eligibility criteria and terms and conditions.
          </p>

          <p>
            {" "}
            10. Candidates from reserved category such as SC/ST/OBC (NCL) can
            also apply against unreserved posts. However, age relaxation will
            not be allowed to such category candidates against unreserved posts.
          </p>

          <p>
            {" "}
            11. In case of variation in name/ surname/ name spelling mentioned
            in the application with that in the respective certificate
            pertaining to education/ professional qualification/ caste
            certificate/ etc. the applicant shall be required to submit a
            certificate from SDM or equivalent to this effect along with the
            respective documents at the time of verification of documents,
            failing which the candidature shall be liable to be cancelled. In
            case of change of surname of female after her marriage, the
            candidate is required to furnish marriage certificate and notarized
            affidavit in this regard.
          </p>

          <p>
            {" "}
            12. Relaxation of standard in selection against reserved vacancies.
            If sufficient numbers of reserved category person are not available
            on the basis of laid down general standards, the general standards
            could be relaxed suitably to fill up the reserved post.
          </p>

          <p>
            {" "}
            13. The cut-off date for determining the age limit and the post
            qualification experience shall be the closing date of application.
            The date of declaration of result / issuance of mark sheet shall be
            deemed to be the date of acquiring the qualification and there shall
            be no relaxation on this account. Post qualification experience on a
            post/ level shall be counted from the said date onwards.
          </p>

          <p>
            {" "}
            14. Candidates working in Government, Semi-Government
            Organization/Public Sector Undertakings and Autonomous Bodies should
            apply through proper channel or furnish &ldquo;NO OBJECTION
            CERTIFICATE&rdquo; at the time of interview. However, in the event
            of difficulty in forwarding the application through proper
            channel/getting NOC from their Parent Department; they may submit an
            undertaking at the time of interview that they will produce proper
            relieving from their organization, in case selected: otherwise, they
            will not be allowed to join.
          </p>

          <p>
            {" "}
            15. Selected candidates will be required to serve in any part of
            India or abroad.
          </p>

          <p>
            {" "}
            16. Where CGPA/OGPA or grading system in a degree/ diploma is
            awarded; equivalent percentage of marks should be indicated in the
            application form as per norms adopted by the University/ Institute.
            The candidate will have to produce a copy of these norms with
            respect to his/ her university/ institute at the time of
            verification of documents.
          </p>

          <p>
            {" "}
            17. Candidates are required to retain a copy of the online submitted
            application form for future reference.
          </p>

          <p>
            {" "}
            18. Number of vacancies mentioned above may increase or decrease
            depending upon the requirement of the Company.
          </p>

          <p>
            {" "}
            19. HSCC reserve the right to cancel this advertisement and/or the
            selection for any of the above posts without assigning any reason.
          </p>

          <p>
            {" "}
            20. Candidates are advised to keep their e-mail ID given in the
            Application Form active at least for one year. No change in e-mail
            ID will be allowed.
          </p>

          <p>
            {" "}
            21. Any corrigendum/ addendum/ errata in respect of the above
            advertisement shall be made available only on our official website
            www.hsccltd.co.in under the heads &ldquo;CAREER&rdquo;. No further
            press advertisement will be given. Hence prospective applicants are
            advised to visit HSCC website regularly for latest updates.
          </p>

          <p>
            {" "}
            22. Any canvassing, directly or indirectly, by the applicant will
            disqualify his/her candidature.
          </p>

          <p>
            {" "}
            23. Only SC/ST/PWD Candidates called for interview who come for
            places beyond a distance of 100 km will be reimbursed actual
            expenses incurred on travel to and from the place of interview on
            production of proof of journey undertaken and onward journey,
            limited to III AC Rail fare or Bus fare chargeable by the shortest
            route from railway station/ Bus stand.
          </p>

          <p>
            {" "}
            24. Any legal proceeding in respect of any matter of claim or
            dispute arising out of this advertisement and/or applications in
            response thereto shall be subject to jurisdictions of Courts at
            Delhi. In case of any ambiguity/ dispute arising on account of
            interpretation other than English, the English version will prevail.
          </p>

          <p>
            {" "}
            25. All correspondence shall be made through e-mail only and no
            communication would be sent through courier/ post or through any
            other mode.
          </p>
        </ol>
      </div>
    </>
  );
};
