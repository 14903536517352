import { useSearchParams } from "react-router-dom";
import "../assests/404.css";

const My404Component = () => {
  const [searchParams] = useSearchParams();
  const addIds = ["HSCCRECT20241", "HSCCRECT20232", "HSCCRECT20233"];
  let addId = searchParams.get("addId");
  if (addIds.includes(addId)) {
    localStorage.setItem("addId", addId);
    window.location.href = "/application";
  }

  return (
    <>
      <div id="main">
        <div className="fof">
          <h1>404</h1>
          <h1>Page Not Found</h1>
        </div>
      </div>
    </>
  );
};

export default My404Component;
