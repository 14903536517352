import React from 'react'
import "./ContactUs.css";

export const ContactUs = () => {

  return (
    <>
          <div className="container pt-3">
          <h3 className=' contactus-title mt-2'>ContactUs</h3>
          <div className='shadow contactus-page-data'>
            <h5 className='contactus-subtitle'>Corporate Office</h5>
            <div className='contactus-data'>E-6(A), Sector 1, Noida - UP - 201301
              <br />
              Tel. - 91-120-2542436-40
              <br />
              Fax - 91-120-2542447
              <br />
              Email - recruitment.hscc@gmail.com
              <br />
              Management
            </div>
          </div>
      </div>
    </>
  )
}
