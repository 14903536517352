/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Col, Button, Container, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import hsccImg from "../../assests/images/logo.png";
import signupImg from "../../assests/images/signupImg.jpg";
import "./RegisterationForm.css";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const api = process.env.REACT_APP_END_POINT;
const INTERNAL_EMAIL = process.env.REACT_APP_INTERNAL_EMAIL;

const RegisterationForm = () => {
  const navigate = useNavigate();
  const addIds = ["HSCCRECT20241", "HSCCRECT20232" ,"HSCCRECT20231"];
  const [searchParams] = useSearchParams();

  let addId = searchParams.get("addId");
  useEffect(() => {
    if (addIds.includes(addId)) {
      localStorage.setItem("addId", addId);
    } else {
      navigate("/dashboard");
    }
  }, []);

  const togglepassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toggleConfirmpassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  const intialValues = {
    isInternalId: "",
    full_name: "",
    email_id: "",
    password: "",
    confirm_password: "",
    register: false,
    sessionToken: null,
  };
  const [regFormData, setRegFormData] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "full_name") value = value.toUpperCase();
    setRegFormData({ ...regFormData, [name]: value });
    setFormErrors("");
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const validate = formValidate(regFormData);
    setFormErrors(validate);
    if (validate.success) {
      try {
        let result = await fetch(`${api}accounts/register`, {
          method: "POST",
          body: JSON.stringify({
            email: regFormData.email_id,
            mobileNumber: regFormData.mobileNumber,
            password: regFormData.password,
            name: regFormData.full_name,
            isInternalId: regFormData.isInternalId,
          }),
          headers: {
            Accept: "accept: application/json",
            "Content-Type": "application/json",
          },
        });
        let status = result.status;
        result = await result.json();
        if (status >= 400) throw new Error(result.message);
        let userEmail = result?.data?.user.email;
        let isVerified = result?.data?.user.isEmailVerified;
        localStorage.setItem("sessionToken", result?.data?.sessionToken);
        localStorage.setItem("user", JSON.stringify({ userEmail, isVerified }));
        // navigate("/verifyemail");
        navigate("/login");
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  var fullName = /^[A-Za-z\s]*$/;
  const formValidate = (val) => {
    const error = { success: true };
    if (!val.isInternalId) {
      error.isInternalId =
        "Please select wether you are internal employee of hscc";
      error.success = false;
    }
    if (!val.full_name) {
      error.full_name = " Enter Your Full Name ";
      error.success = false;
    }
    if (!val.full_name.match(fullName)) {
      error.full_name = "Name Only contains Alphabets";
      error.success = false;
    }
    if (!val.email_id) {
      error.email_id = "Please Enter mail id ";
      error.success = false;
    }
    if (!val.mobileNumber) {
      error.mobileNumber = "Please Enter mobile number";
      error.success = false;
    }
    // if (val.email_id.search(/.com/) == -1) {
    //   error.email_id = "Please Enter Proper Email ";
    //   error.success = false;
    // }
    if (val.email_id) {
      const emailExtension = val.email_id.split("@")[1];
      if (val.isInternalId === "Yes" && emailExtension !== INTERNAL_EMAIL) {
        error.email_id = "Provided email id is not internal email";
        error.success = false;
      }
    }
    if (!val.password) {
      error.password = "Please Enter Password ";
      error.success = false;
    } else if (val.password.length < 8) {
      error.password = "Please Enter 8 Characters ";
      error.success = false;
    } else if (val.password.search(/[0-9]/) === -1) {
      error.password = "Please Enter Atleast One Numeric Character ";
      error.success = false;
    } else if (val.password.search(/[A-Z a-z]/) === -1) {
      error.password = "Please Enter Atleast One Upper Case Character ";
      error.success = false;
    } else if (val.password.search(/[!@#$%^&*()_\-+=<>,?]/) === -1) {
      error.password = "Please Enter Atleast One Special Symbol ";
      error.success = false;
    }
    if (!val.confirm_password) {
      error.confirm_password = "Please conirm password";
      error.success = false;
    } else if (val.password !== val.confirm_password) {
      error.confirm_password = "Make sure your passwords match";
      error.success = false;
    }

    return error;
  };

  return (
    <>
      <div className="d-flex main-container shadow">
        <Container className="d-flex justify-content-center align-items-center">
          <div className="main-content">
            <div className="row shadow">
              <div className="col-6 p-0">
                <div className="para-content">
                  <div>
                    <img className="signup-page-img" alt="" src={hsccImg} />
                  </div>

                  <div className="text-data">
                    <ul>
                      <h3 className="text-heading">
                        Welcome To The HSCC Career{" "}
                      </h3>
                      <h4 className="text-subheading"> Why Signup?</h4>
                      <li className="text-data">
                        Find the right job and grow your career
                      </li>
                      <li className="text-data">
                        Manage your profile and apply
                      </li>
                      <li className="text-data">
                        Closing Date 17:00 hrs on 29.04.202a
                      </li>
                      <li className="text-data">
                        For any Technical Query Call:- +91-9910659965, between
                        (10am to 6pm)
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img
                      className="signup-page-data-img"
                      src={signupImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0">
                <Card className=" main-card">
                  <Card.Body>
                    <div className="mb-3 mt-md-4">
                      <h2 className="fw-bold mb-2 text-center text-uppercase text-primary">
                        HSCC (INDIA) LIMITED
                      </h2>
                      <div className="mb-3 d-flex justify-content-center align-items-center">
                        <Form onSubmit={HandleSubmit}>
                          <Col md={3}>
                            <Form.Label className="d-flex flex-row">
                              <Form.Check
                                className="internal-external_radio"
                                id="internal"
                                type="radio"
                                name="isInternalId"
                                label="Internal"
                                value={"Yes"}
                                checked={regFormData.isInternalId === "Yes"}
                                onChange={handleChange}
                              />
                              <Form.Check
                                className="internal-external_radio"
                                id="external"
                                type="radio"
                                name="isInternalId"
                                label="External"
                                value={"No"}
                                checked={regFormData.isInternalId === "No"}
                                onChange={handleChange}
                              />
                            </Form.Label>
                            <div className="required-field">
                              {formErrors.isInternalId}
                            </div>
                          </Col>
                          <Form.Group className="mb-3" controlId="Name">
                            <span className="required-field">*</span>
                            <Form.Label className="text-center">
                              Full Name
                            </Form.Label>
                            <Form.Control
                              className="reg-form-input-field"
                              type="text"
                              placeholder="Enter Name"
                              autoComplete="off"
                              name="full_name"
                              value={regFormData.full_name}
                              onChange={handleChange}
                            />
                            <div className="required-field">
                              {formErrors.full_name}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <span className="required-field">*</span>
                            <Form.Label className="text-center">
                              Email address
                            </Form.Label>
                            <Form.Control
                              className="reg-form-input-field"
                              type="email"
                              placeholder="Enter email"
                              autoComplete="off"
                              name="email_id"
                              value={regFormData.email_id}
                              onChange={handleChange}
                            />
                            <div className="required-field">
                              {formErrors.email_id}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicMobileNumber"
                          >
                            <span className="required-field">*</span>
                            <Form.Label className="text-center">
                              Mobile Number
                            </Form.Label>
                            <Form.Control
                              className="reg-form-input-field"
                              type="number"
                              placeholder="Enter mobile number"
                              autoComplete="off"
                              name="mobileNumber"
                              value={regFormData.mobileNumber}
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <span className="required-field">*</span>
                            <Form.Label>Password</Form.Label>
                            <div className="reg-form-input-field-icon">
                              <Form.Control
                                className="reg-form-input-field"
                                type={passwordType}
                                placeholder="Password"
                                autoComplete="off"
                                name="password"
                                value={regFormData.password}
                                onChange={handleChange}
                              />
                              <span
                                onClick={togglepassword}
                                style={{
                                  margin: ".5rem -2rem",
                                  cursor: "pointer",
                                }}
                              >
                                {passwordType === "password" ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </span>
                            </div>
                            <div className="required-field">
                              {formErrors.password}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="cformBasicPassword"
                          >
                            <span className="required-field">*</span>
                            <Form.Label>Confirm Password</Form.Label>
                            <div className="reg-form-input-field-icon">
                              <Form.Control
                                className="reg-form-input-field"
                                type={confirmPasswordType}
                                placeholder="Password"
                                autoComplete="off"
                                name="confirm_password"
                                value={regFormData.confirm_password}
                                onChange={handleChange}
                              />
                              <span
                                onClick={toggleConfirmpassword}
                                style={{
                                  margin: ".5rem -2rem",
                                  cursor: "pointer",
                                }}
                              >
                                {confirmPasswordType === "password" ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </span>
                            </div>
                            <div className="required-field">
                              {formErrors.confirm_password}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicCheckbox"
                          ></Form.Group>
                          <Button
                            className="btn btn-primary pull-right"
                            type="submit"
                          >
                            Create Account
                          </Button>
                        </Form>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Already have an account??{" "}
                        <a
                          className="text-primary fw-bold c-p"
                          onClick={() => navigate("/login")}
                        >
                          Sign In
                        </a>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default RegisterationForm;
