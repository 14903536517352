import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import HeaderBar from "./components/HeaderBar";
import "./assests/style.css";
import RegisterationForm from "./components/forms/RegisterationForm";
import LoginForm from "./components/forms/LoginForm";
import VerifyEmail from "./components/forms/VerifyEmail";
import ForgetPassword from "./components/forms/ForgetPassword";
import { Routes, Route, Navigate } from "react-router-dom";
import Application from "./components/application/Aapplication";
import { useEffect, useState } from "react";
import Dashboard from "./components/Dashboard";
import Formpreview from "./components/application/Formpreview";
import { AboutUs } from "./components/Navigation Component/AboutUs";
import { TermsAndConditions } from "./components/Navigation Component/TermsAndConditions";
import { PrivacyPolicy } from "./components/Navigation Component/PrivacyPolicy";
import { RefundAndCancellation } from "./components/Navigation Component/RefundAndCancellation";
import { ServiceDetailsWithPricingStruct } from "./components/Navigation Component/ServiceDetailsWithPricingStruct";
import { ContactUs } from "./components/Navigation Component/ContactUs";
import AppList from "./components/admin/application-list";
import UsersForm from "./components/admin/usersForm";
import My404Component from "./components/My404Component";

function App() {
  const [isLoggedIn, setisLoggedIn] = useState(null);
  const logInUser = () => {
    setisLoggedIn(true);
  };
  const logOutUser = () => {
    setisLoggedIn(false);
  };
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setisLoggedIn(true);
    }
  }, []);
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />{" "}
      <HeaderBar logOutUser={logOutUser} />
      <Routes>
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route
          path="/refundandcancellation"
          element={<RefundAndCancellation />}
        />
        <Route
          path="/servicedetailwithprice"
          element={<ServiceDetailsWithPricingStruct />}
        />
        <Route path="/contactus" element={<ContactUs />} />
        <Route
          exact
          path="/"
          element={
            isLoggedIn ? <Navigate to="/dashboard" /> : <RegisterationForm />
          }
        />
        <Route
          exact
          path="/registration"
          element={
            isLoggedIn ? <Navigate to="/dashboard" /> : <RegisterationForm />
          }
        />
        <Route
          exact
          path="/login"
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <LoginForm logInUser={logInUser} />
            )
          }
        />
        <Route
          exact
          path="/verifyemail"
          element={isLoggedIn ? <Navigate to="/dashboard" /> : <VerifyEmail />}
        />
        <Route
          exact
          path="/forgetpassword"
          element={
            isLoggedIn ? <Navigate to="/dashboard" /> : <ForgetPassword />
          }
        />
        <Route
          exact
          path="/dashboard"
          element={isLoggedIn ? <Dashboard /> : <RegisterationForm />}
        />
        <Route
          exact
          path="/application"
          element={isLoggedIn ? <Application /> : <RegisterationForm />}
        />

        <Route
          exact
          path="/preview"
          element={isLoggedIn ? <Formpreview /> : <RegisterationForm />}
        />
        <Route exact path="/users-form/:applicationId" element={<UsersForm />} />


        <Route exact path="/admin" element={<AppList />} />
        <Route exact path="/admin/users-form" element={<UsersForm />} />
        <Route path="*" element={<My404Component />} />
      </Routes>
    </div>
  );
}

export default App;
