/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Button, Container, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { get } from "../services/api";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "./LoginForm.css";
import hsccImg from "../../assests/images/logo.png";
import signupImg from "../../assests/images/signupImg.jpg";
import { toast } from "react-toastify";

const api = process.env.REACT_APP_END_POINT;

const LoginForm = ({ logInUser }) => {
  const navigate = useNavigate();
  const intialValues = {
    email_add: "",
    password: "",
    login: false,
    accessToken: null,
  };
  const [formData, setFormData] = useState(intialValues);
  const [passwordType, setPasswordType] = useState("password");
  const [formErrors, setFormErrors] = useState({});

  const togglepassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setFormErrors("");
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    const validate = formValidate(formData);
    setFormErrors(validate);
    if (validate.success) {
      try {
        let result = await fetch(`${api}accounts/login`, {
          method: "POST",
          body: JSON.stringify({
            email: formData.email_add,
            password: formData.password,
          }),
          headers: {
            Accept: "accept: application/json",
            "Content-Type": "application/json",
          },
        });
        let status = result.status;
        result = await result.json();
        if (status >= 400) throw new Error(result.message);
        localStorage.setItem("accessToken", result?.data?.accessToken);
        logInUser(true);

        let whoami = await get(`${api}accounts/whoami`);
        whoami = await whoami.json();
        const { isEmailVerified, isInternalId, name, email, role } =
          whoami.data;
        localStorage.setItem(
          "userData",
          JSON.stringify({ isEmailVerified, isInternalId, name, email })
        );
        let addId = localStorage.getItem("addId");
        if (role === "admin") {
          navigate("/admin");
        } else if (addId) {
          navigate("/application?addId=" + addId);
        } else {
          navigate("/dashboard");
        }
      } catch (error) {
        console.log(error, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        toast.error(error.message);
        if (error.message === "your email is not verified")
          navigate("/verifyemail");
      }
    }
  };

  const formValidate = (val) => {
    const error = { success: true };
    if (!val.email_add) {
      error.email_add = "Please Enter email id ";
      error.success = false;
    } else if (!val.password) {
      error.password = "Please Enter Password ";
      error.success = false;
    }
    return error;
  };

  return (
    <>
      <div className="d-flex login-main-container shadow">
        <Container className="d-flex justify-content-center align-items-center">
          <div className="login-main-content">
            <div className="row shadow">
              <div className="col-6 p-0">
                <div className="para-content">
                  <div>
                    <img className="login-form-img" alt="" src={hsccImg} />
                  </div>
                  <div className="text-data">
                    <ul>
                      <h3 className="text-heading">Welcome Back! </h3>
                      <h4 className="text-subheading">
                        {" "}
                        You Need To Login Here With Your Details!
                      </h4>
                      <li className="text-data">
                        Find the right job and grow your career
                      </li>
                      <li className="text-data">
                        Manage your profile and apply
                      </li>
                      <li className="text-data">
                        Closing Date 17:00 hrs on 29.04.2024
                      </li>
                      <li className="text-data">
                        For any Technical Query Call:- +91-9910659965 (Nitin Sharma), between
                        (10am to 6pm)
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img
                      className="login-form-data-img"
                      src={signupImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0">
                <Card className=" login-main-card">
                  <Card.Body>
                    <div className="mb-3 mt-md-4">
                      <h2 className="fw-bold mb-2 text-center text-uppercase text-primary">
                        Login to HSCC (INDIA) LIMITED
                      </h2>
                      <div className="mb-3 d-flex justify-content-center align-items-center">
                        <Form onSubmit={HandleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="text-center">
                              Email address
                            </Form.Label>
                            <Form.Control
                              className="login-form-input-field"
                              type="email"
                              placeholder="Enter email"
                              autoComplete="off"
                              onChange={handleChange}
                              value={formData?.email_add}
                              name="email_add"
                            />
                            <div className="required-field">
                              {formErrors.email_add}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <div className="login-form-input-field-icon">
                              <Form.Control
                                className="login-form-input-field"
                                type={passwordType}
                                placeholder="Password"
                                onChange={handleChange}
                                value={formData?.password}
                                name="password"
                              />
                              <span
                                onClick={togglepassword}
                                style={{
                                  margin: ".5rem -2rem",
                                  cursor: "pointer",
                                }}
                              >
                                {passwordType === "password" ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </span>
                            </div>
                            <div className="required-field">
                              {formErrors.password}
                            </div>
                          </Form.Group>
                          <div className="d-flex justify-content-center align-items-center">
                            <div className="col-6 p-0 login-fp-link">
                              <a
                                className="text-primary fw-bold  c-p"
                                onClick={() => navigate("/forgetpassword")}
                              >
                                Forgot Password
                              </a>
                            </div>
                            <div className="col-6 p-0">
                              <Button
                                variant="primary pull-right"
                                className="login-button"
                                type="submit"
                              >
                                Login
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Already have an account??{" "}
                        <a
                          className="text-primary fw-bold c-p"
                          onClick={() => navigate("/")}
                        >
                          Create account
                        </a>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default LoginForm;
