/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { Button, Container, Card, Form } from "react-bootstrap";
import { useNavigate } from "react-router";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import hsccImg from "../../assests/images/logo.png";
import signupImg from "../../assests/images/signupImg.jpg";
import "./ForgetPassword.css";
import { toast } from "react-toastify";

const api = process.env.REACT_APP_END_POINT;

const ForgetPassword = () => {
  const navigate = useNavigate();
  const intialValues = {
    email_id: "",
    otp: "",
    password: "",
    confirm_password: "",
    sessionToken: null,
  };
  const [formData, setFormData] = useState(intialValues);
  const [showSendOTP, setShowSendOTP] = useState(null);
  const [showResendOTP, setShowResendOTP] = useState(null);
  const [showOTP, setShowOTP] = useState(null);
  const [showOTPField, setShowOTPField] = useState(null);
  const [showPassword, setShowPassword] = useState(null);
  const [showPasswordField, setShowPasswordField] = useState(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(null);
  const [showConfirmPasswordField, setShowConfirmPasswordField] =
    useState(null);
  const [showTimer, setShowTimer] = useState(null);
  const [showSubmit, setShowSubmit] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const togglepassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toggleConfirmpassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setFormErrors("");
  };
  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("00:00");
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 120);
    return {
      total,
      minutes,
      seconds,
    };
  }
  function startTimer(deadline) {
    let { total, minutes, seconds } = getTimeRemaining(deadline);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      clearInterval(intervalRef.current);
    }
  }
  function clearTimer(endtime) {
    setTimer("01:59");
    if (intervalRef.current) clearInterval(intervalRef.current);
    const id = setInterval(() => {
      startTimer(endtime);
    }, 1000);
    intervalRef.current = id;
  }
  function getDeadlineTime() {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  }

  useEffect(() => {
    setShowSendOTP(true);
    setShowResendOTP(false);
    setShowOTP(false);
    setShowOTPField(false);
    setShowPassword(false);
    setShowPasswordField(false);
    setShowConfirmPassword(false);
    setShowConfirmPasswordField(false);
    setShowSubmit(false);
    setShowTimer(false);
    clearTimer(getDeadlineTime());
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const sendOTP = async (e) => {
    e.preventDefault();
    try {
      let fpVerifyEmail = await fetch(`${api}accounts/forgot-password`, {
        method: "POST",
        body: JSON.stringify({
          email: formData.email_id,
        }),
        headers: {
          Accept: "accept: application/json",
          "Content-Type": "application/json",
        },
      });
      let status = fpVerifyEmail.status;
      fpVerifyEmail = await fpVerifyEmail.json();
      if (status >= 400) throw new Error(fpVerifyEmail.message);
      if (fpVerifyEmail?.data?.sessionToken)
        localStorage.setItem("sessionToken", fpVerifyEmail?.data?.sessionToken);
      setShowSendOTP(false);
      setShowResendOTP(true);
      setShowOTP(true);
      setShowOTPField(true);
      setShowPassword(true);
      setShowPasswordField(true);
      setShowConfirmPassword(true);
      setShowConfirmPasswordField(true);
      setShowSubmit(true);
      setShowTimer(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const resendOTP = async () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    clearTimer(getDeadlineTime());
    try {
      let result = await fetch(`${api}accounts/resend-otp`, {
        method: "POST",
        body: JSON.stringify({
          email: formData.email_id,
        }),
        headers: {
          Accept: "accept: application/json",
          "Content-Type": "application/json",
        },
      });
      let status = result.status;
      result = await result.json();
      if (status > 400) throw new Error(result.message);
      if (result?.sessionToken)
        localStorage.setItem("sessionToken", result?.sessionToken);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const validate = formValidate(formData);
    setFormErrors(validate);
    let sessionToken = localStorage.getItem("sessionToken");
    if (validate.success) {
      try {
        let resetPass = await fetch(`${api}accounts/reset-password`, {
          method: "PUT",
          body: JSON.stringify({
            sessionToken: sessionToken,
            otp: formData.otp,
            password: formData.password,
          }),
          headers: {
            Accept: "accept: application/json",
            "Content-Type": "application/json",
          },
        });
        let status = resetPass.status;
        resetPass = await resetPass.json();
        if (status > 400) throw new Error(resetPass.message);
        navigate("/login");
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const formValidate = (val) => {
    const error = { success: true };
    if (!val.email_id) {
      error.email_id = "Please Enter email id ";
      error.success = false;
    }
    if (!val.otp) {
      error.otp = "Please Enter OTP";
      error.success = false;
    }
    if (!val.password) {
      error.password = "Please Enter Password ";
      error.success = false;
    } else if (val.password.length < 8) {
      error.password = "Please Enter 8 Characters ";
      error.success = false;
    } else if (val.password.search(/[0-9]/) === -1) {
      error.password = "Please Enter Atleast One Numeric Character ";
      error.success = false;
    } else if (val.password.search(/[A-Za-z]/) === -1) {
      error.password = "Please Enter Atleast One Upper Case Character ";
      error.success = false;
    } else if (val.password.search(/[!@#$%^&*()_\-+=<>,?]/) === -1) {
      error.password = "Please Enter Atleast One Special Symbol ";
      error.success = false;
    }
    if (!val.confirm_password) {
      error.confirm_password = "Please confirm password";
      error.success = false;
    } else if (val.password !== val.confirm_password) {
      error.confirm_password = "Make sure your passwords match";
      error.success = false;
    }

    return error;
  };

  return (
    <>
      <div className="d-flex fp-main-container shadow">
        <Container className="d-flex justify-content-center align-items-center">
          <div className="fp-main-content">
            <div className="row shadow">
              <div className="col-6 p-0">
                <div className="para-content">
                  <div>
                    <img className="signup-page-img" alt="" src={hsccImg} />
                  </div>
                  <div className="text-data">
                    <ul>
                      <h3 className="text-heading">
                        Welcome To The HSCC Career{" "}
                      </h3>
                      <h4 className="text-subheading"> Why Signup?</h4>
                      <li className="text-data">
                        Find the right job and grow your career
                      </li>
                      <li className="text-data">
                        Build your profile and let recruiters find you
                      </li>
                      <li className="text-data">
                        Manage your profile and apply
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img
                      className="signup-page-data-img"
                      src={signupImg}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 p-0">
                <Card className="fp-main-card">
                  <Card.Body>
                    <div className="mb-3 mt-md-4">
                      <h2 className="fw-bold mb-2 text-center text-uppercase text-primary">
                        Forgot Password
                      </h2>
                      <div className="mb-3 d-flex justify-content-center align-items-center">
                        <Form onSubmit={HandleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label className="text-center">
                              Email address
                            </Form.Label>
                            <Form.Control
                              className="fp-form-input-field"
                              type="email"
                              placeholder="Enter email"
                              autoComplete="off"
                              name="email_id"
                              value={formData.email_id}
                              onChange={handleChange}
                            />
                            <div className="required-field">
                              {formErrors.email_id}
                            </div>
                          </Form.Group>
                          {showSendOTP ? (
                            <div className="d-grid mb-3">
                              <Button
                                variant="primary"
                                type="submit"
                                onClick={sendOTP}
                              >
                                Send OTP
                              </Button>
                            </div>
                          ) : null}
                          {showResendOTP ? (
                            <div className="d-grid mb-3">
                              <Button
                                variant="primary"
                                type="submit"
                                onClick={resendOTP}
                              >
                                Resend OTP
                              </Button>
                            </div>
                          ) : null}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            {showOTP ? <Form.Label>OTP</Form.Label> : null}
                            {showOTPField ? (
                              <Form.Control
                                className="fp-form-input-field"
                                type="password"
                                placeholder="OTP"
                                autoComplete="off"
                                name="otp"
                                value={formData.otp}
                                onChange={handleChange}
                              />
                            ) : null}
                            <div className="required-field">
                              {formErrors.otp}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {showPassword ? (
                              <Form.Label className="text-center">
                                Password
                              </Form.Label>
                            ) : null}
                            {showPasswordField ? (
                              <div className="fp-form-input-field-icon">
                                <Form.Control
                                  className="fp-form-input-field"
                                  type={passwordType}
                                  placeholder="New Password"
                                  autoComplete="off"
                                  name="password"
                                  value={formData.password}
                                  onChange={handleChange}
                                />
                                <span
                                  onClick={togglepassword}
                                  style={{
                                    margin: ".5rem -2rem",
                                    cursor: "pointer",
                                  }}
                                >
                                  {passwordType === "password" ? (
                                    <AiFillEyeInvisible />
                                  ) : (
                                    <AiFillEye />
                                  )}
                                </span>
                              </div>
                            ) : null}
                            <div className="required-field">
                              {formErrors.password}
                            </div>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {showConfirmPassword ? (
                              <Form.Label className="text-center">
                                Confirm Password
                              </Form.Label>
                            ) : null}
                            {showConfirmPasswordField ? (
                              <div className="fp-form-input-field-icon">
                                <Form.Control
                                  className="fp-form-input-field"
                                  type={confirmPasswordType}
                                  placeholder="Confirm Password"
                                  autoComplete="off"
                                  name="confirm_password"
                                  value={formData.confirm_password}
                                  onChange={handleChange}
                                />{" "}
                                <span
                                  onClick={toggleConfirmpassword}
                                  style={{
                                    margin: ".5rem -2rem",
                                    cursor: "pointer",
                                  }}
                                >
                                  {confirmPasswordType === "password" ? (
                                    <AiFillEyeInvisible />
                                  ) : (
                                    <AiFillEye />
                                  )}
                                </span>
                              </div>
                            ) : null}

                            <div className="required-field">
                              {formErrors.confirm_password}
                            </div>
                          </Form.Group>

                          {showSubmit ? (
                            <div className="d-grid">
                              <Button variant="primary" type="submit">
                                Submit
                              </Button>
                            </div>
                          ) : null}
                          {showTimer ? (
                            <div className="d-flex justify-content-center form-text fw-bold">
                              Time Left<span>{timer}</span>
                            </div>
                          ) : null}
                          <div className="mt-3">
                            <p className="mb-0  text-center">
                              Don't have an account??
                              <a
                                onClick={() => navigate("/registration")}
                                className="text-primary fw-bold c-p"
                              >
                                Create account
                              </a>
                            </p>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ForgetPassword;
