/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { deleteUpload, fileUpload, get, patch } from "../services/api";
import { ctcRequired, expRequired } from "../services/helper";

const api = process.env.REACT_APP_END_POINT;
const baseUrl = process.env.REACT_APP_FILE_BASE_URL;

/* eslint-disable import/no-anonymous-default-export */
export default ({
  applicationData,
  step,
  handleBackSteps,
  handleNextSteps,
}) => {
  const oneMB = 1000000;
  const workExpInitialValue = {
    employerName: "",
    employerAddr: "",
    startedOn: "",
    endedOn: "",
    totalWork: "",
    // payScale: "",
    empProof: "",
  };
  const data = applicationData;
  const workExp =
    data?.workExpDetails && data?.workExpDetails.length
      ? data?.workExpDetails
      : [workExpInitialValue];

  const [formData, setFormData] = useState(data);
  const [ExpFormData, setExpFormData] = useState([]);
  const [formExpErrors, setFormExpErrors] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isPayScale, setisPayScale] = useState(false);
  useEffect(() => {
    setExpFormData([...workExp]);
    // setFormData(data);
    const acontroler = new AbortController();
    async function getData() {
      try {
        let data = {};
        let applicationId = localStorage.getItem("applicationId");
        if (applicationId) {
          let response = await get(`${api}application/get/${applicationId}`);
          const status = response.status;
          if (status >= 400) throw new Error("Somthing went wrong");
          response = await response.json();
          data = response.data;
        }
        setFormData({ ...data });
      } catch (error) {
        toast.error(error.message);
      }
    }
    if (step === 3) getData();

    if (
      formData?.typeOfOrg === "stateGovt" ||
      formData?.typeOfOrg === "centralGovt" ||
      formData?.typeOfOrg === "psu"
    ) {
      setisPayScale(true);
    } else {
      setisPayScale(false);
    }
    return () => {
      acontroler.abort();
    };
  }, [step]);

  let addFormFields = () => {
    setExpFormData([...ExpFormData, workExpInitialValue]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...ExpFormData];
    newFormValues.splice(i, 1);
    setExpFormData(newFormValues);
    let newOverallExp = newFormValues[newFormValues.length - 1].totalWork;
    setFormData({ ...formData, overallExp: newOverallExp });
  };

  const handleChange = (e, index) => {
    let { name, value } = e.target;
    if (e.target.type === "text") {
      value = value.toUpperCase();
    }
    if (e.target.type === "date") {
      if (value.length > 10) return;
    }
    if (index !== null) {
      const formArr = [...ExpFormData];
      formArr[index][name] = value;
      setExpFormData(formArr);
    } else {
      setFormData({ ...formData, [name]: value });
      setFormExpErrors("");
      setFormErrors("");
    }
    setFormExpErrors("");
    setFormErrors("");
    let orgType = value;
    if (name === "typeOfOrg") {
      if (
        orgType === "stateGovt" ||
        orgType === "centralGovt" ||
        orgType === "psu"
      ) {
        setisPayScale(true);
      } else {
        setisPayScale(false);
      }
    }
  };

  const calculateTotalExp = (e, index) => {
    const formArr = [...ExpFormData];
    let endedOn = formArr[index].endedOn;
    let startedOn = formArr[index].startedOn;
    let sd = new Date(startedOn);
    let ed = new Date(endedOn);
    // let timestamp1 = Date.parse(sd);
    // let timestamp2 = Date.parse(ed);
    // if (!isNaN(timestamp1) && !isNaN(timestamp2)) return;
    if (index > 0) {
      let previousEd = formArr[index - 1].endedOn;
      previousEd = new Date(previousEd).getTime();
      if (sd.getTime() <= previousEd) {
        toast.error("Start date can not overlap on last org. end date");
      }
    }
    var diff = Math.floor(ed.getTime() - sd.getTime());
    var day = 1000 * 60 * 60 * 24;
    var days = diff / day;
    formArr[index].totalWork = "";
    setExpFormData(formArr);
    if (days > 0 && sd && ed) {
      var diffDate = new Date(ed - sd);
      var timestamp = Date.parse(diffDate);
      if (isNaN(timestamp) === false) {
        var message =
          diffDate.toISOString().slice(0, 4) -
          1970 +
          "Y " +
          diffDate.getMonth() +
          "M " +
          (diffDate.getDate() - 1) +
          "D";

        formArr[index].totalWork = message;
        setExpFormData(formArr);
        calculateOverAllExp(index, formArr);
      }
    }
  };

  function calculateOverAllExp(index, formArr) {
    // let endedOn = formArr[formArr.length - 1].endedOn;
    // let startedOn = formArr[0].startedOn;
    // let sd = new Date(startedOn);
    // let ed = new Date(endedOn);
    // var startDate = new Date(sd);
    // var diffDate = new Date(ed - startDate);
    let dateDiff = 0;
    for (let i = 0; i < formArr.length; i++) {
      dateDiff +=
        new Date(formArr[i].endedOn).getTime() -
        new Date(formArr[i].startedOn).getTime();
    }
    let diffDate = new Date(dateDiff);
    var timestamp = Date.parse(diffDate);
    if (isNaN(timestamp) === false) {
      var message =
        diffDate.toISOString().slice(0, 4) -
        1970 +
        "Y " +
        diffDate.getMonth() +
        "M " +
        (diffDate.getDate() - 1) +
        "D";
      setFormData({ ...formData, overallExp: message });
    }
  }

  const handleUploadClick = async (fileName, index) => {
    let docEle = "";
    if (index === null) {
      docEle = document.getElementById(fileName);
    } else {
      docEle = document.getElementById(fileName + index);
    }
    const proofDocument = docEle?.files[0];
    if (!proofDocument) return;
    if (proofDocument > oneMB) {
      alert("File size should be less then 1mb");
      docEle.value = "";
      return;
    }
    const data = new FormData();
    data.append(fileName, proofDocument);
    fileUpload(`${api}accounts/upload-file`, data)
      .then((res) => res.json())
      .then((fileUploadResp) => {
        console.log("File Uploaded");
        let filePath = fileUploadResp.filePath;
        setExpFormData((prev) => {
          const formArr = [...prev];
          if (index === null) {
            formData[fileName] = filePath;
          } else {
            formArr[index][fileName] = filePath;
          }
          return formArr;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteFile = async (fileName, filePath, index) => {
    deleteUpload(`${api}accounts/delete-file`, filePath)
      .then((fileDeleteResp) => {
        console.log("File Deleted");
        if (index !== null) {
          setExpFormData((prev) => {
            const formArr = [...prev];
            formArr[index][fileName] = "";
            return formArr;
          });
        } else {
          setFormData({ ...formData, [fileName]: "" });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formValidate = (val, arrVal) => {
    const errorArr = [];
    for (let i = 0; i < arrVal.length; i++) {
      const error = { success: true };
      if (!arrVal[i].employerName) {
        error.employerName = "Please enter employer name";
        error.success = false;
      }
      if (!arrVal[i].employerAddr) {
        error.employerAddr = "Please enter employer address";
        error.success = false;
      }
      if (!arrVal[i].postHeld) {
        error.postHeld = "Please provide post held";
        error.success = false;
      }
      if (!arrVal[i].startedOn) {
        error.startedOn = "Please select start date";
        error.success = false;
      }

      if (!arrVal[i].endedOn) {
        error.endedOn = "Please select end date";
        error.success = false;
      }
      if (!arrVal[i].totalWork) {
        error.totalWork = "Total work experience is required";
        error.success = false;
      }

      if (!arrVal[i].payScaleCTC) {
        error.payScaleCTC = "Please provide Payscale / CTC";
        error.success = false;
      }
      if (!arrVal[i].empProof) {
        error.empProof = "Please upload your employement proof";
        error.success = false;
      }
      if (!arrVal[i].natureOfExp) {
        error.natureOfExp = "Please provide nature of experience";
        error.success = false;
      }

      errorArr.push(error);
    }

    const error = { success: true };
    if (!val.nameOfCurrentOrg) {
      error.nameOfCurrentOrg = "Please enter your current employer name";
      error.success = false;
    }
    if (!val.typeOfOrg) {
      error.typeOfOrg = "Please select type of organisation";
      error.success = false;
    }
    if (!val.overallExp) {
      error.overallExp = "Total work experience in years";
      error.success = false;
    }
    let data = formData;
    if (val.typeOfOrg && (val.currentCTC || val.payScale)) {
      let level = data.level;
      let orgType = val.typeOfOrg;
      let payType = val.payScaleType;
      let minCTC = ctcRequired[`${level}_${orgType}_MIN`];
      let maxCTC = ctcRequired[`${level}_${orgType}_MAX`];
      // let minCTC_IDA = ctcIDARequired[`${level}_${orgType}_MIN`];
      // let maxCTC_IDA = ctcIDARequired[`${level}_${orgType}_MAX`];
      let payScale = val.payScale ? val.payScale : 0;
      let currentCTC = val.currentCTC ? val.currentCTC : 0;
      if (orgType === "private" || orgType === "other") {
        if (currentCTC < minCTC || currentCTC > maxCTC) {
          error.currentCTC = `Your CTC should be minimum ${minCTC}`;
          error.success = false;
        }
      }
      if (
        orgType === "stateGovt" ||
        orgType === "centralGovt" ||
        orgType === "psu"
      ) {
        if (!val.payScale) {
          error.payScale = `Your select pay scale`;
          error.success = false;
        }
      }
    } else {
      error.currentCTC = `Please provide your current ctc / payscale`;
      error.payScale = `Please provide your current ctc / payscale`;
      error.success = false;
    }

    if (val.overallExp) {
      let level = data.level;
      let exp = expRequired[data.level];
      let totalExp = val.overallExp.split("Y ")[0];
      totalExp = parseInt(totalExp);
      if (!isNaN(totalExp)) {
        if (totalExp < exp) {
          error.overallExp = `Your total experience should be minimum ${exp} for level ${level}`;
          error.success = false;
        }
      } else {
        error.overallExp = `Your total experience should be minimum ${exp} for level ${level}`;
        error.success = false;
      }
    }

    // return { success: true };
    return { errorArr, error };
  };

  async function HandleSubmit() {
    const { errorArr, error } = formValidate(formData, ExpFormData);
    setFormExpErrors(errorArr);
    setFormErrors(error);
    let submit = [];
    for (let err of errorArr) {
      submit.push(err.success);
    }
    if (!submit.includes(false) && error.success) {
      const {
        nameOfCurrentOrg,
        typeOfOrg,
        overallExp,
        currentCTC,
        ctcProof,
        payScaleType,
        payScale,
      } = formData;
      const reqBody = {
        nameOfCurrentOrg,
        typeOfOrg,
        overallExp,
        workExpDetails: ExpFormData,
        currentCTC,
        ctcProof,
        payScaleType,
        payScale,
      };
      let applicationId = localStorage.getItem("applicationId");
      let registerData = await patch(
        `${api}application/work-info/${applicationId}`,
        reqBody
      );
      const statusCode = registerData.status;
      registerData = await registerData.json();
      if (statusCode < 400) handleNextSteps();
    }
  }

  return (
    <div
      className={`tab-pane ${step === 3 ? "active" : ""}`}
      role="tabpanel"
      id="step3"
    >
      <h4 className="text-center step-head">
        <u>Work Info</u>
      </h4>
      <h5>Experience Details (POST Qualification Only) - Including Current Org <span className="text-danger">*</span></h5>
      {ExpFormData.map((element, index) => (
        <div className="card mb-5 p-3" key={index}>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Name of Employer  <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="employerName"
                  placeholder=""
                  value={element.employerName}
                  onChange={(e) => handleChange(e, index)}
                />
                <div className="required-field">
                  {formExpErrors[index]?.employerName}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>
                  Address of Employer <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="employerAddr"
                  value={element.employerAddr}
                  placeholder=""
                  onChange={(e) => handleChange(e, index)}
                />
                <div className="required-field">
                  {formExpErrors[index]?.employerAddr}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Post Held <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="postHeld"
                  value={element.postHeld}
                  placeholder=""
                  onChange={(e) => handleChange(e, index)}
                />
                <div className="required-field">
                  {formExpErrors[index]?.postHeld}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Start Date <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  max="2024-04-20"
                  name="startedOn"
                  placeholder=""
                  value={element.startedOn}
                  onChange={(e) => {
                    handleChange(e, index);
                    calculateTotalExp(e, index);
                  }}
                />
                <div className="required-field">
                  {formExpErrors[index]?.startedOn}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  End Date <span>*</span>
                </label>
                <input
                  className="form-control"
                  type="date"
                  max="2024-04-20"
                  name="endedOn"
                  value={element.endedOn}
                  onChange={(e) => {
                    handleChange(e, index);
                    calculateTotalExp(e, index);
                  }}
                  placeholder=""
                />
                <div className="required-field">
                  {formExpErrors[index]?.endedOn}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Total work experience <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="totalWork"
                  placeholder=""
                  value={element.totalWork}
                  disabled={true}
                  // onChange={(e) => handleChange(e, index)}
                />
                <div className="required-field">
                  {formExpErrors[index]?.totalWork}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Nature of Experience <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="natureOfExp"
                  value={element.natureOfExp}
                  onChange={(e) => handleChange(e, index)}
                  placeholder=""
                />
                <div className="required-field">
                  {formExpErrors[index]?.natureOfExp}
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label>
                  Payscale / CTC <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="payScaleCTC"
                  value={element.payScaleCTC}
                  onChange={(e) => handleChange(e, index)}
                  placeholder=""
                />
                <div className="required-field">
                  {formExpErrors[index]?.payScaleCTC}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label>
                  Document Proof <span className="text-danger">*</span>
                </label>
                <input
                  accept="application/pdf"
                  className="form-control"
                  type="file"
                  name="empProof"
                  id={`empProof${index}`}
                />
                <div className="required-field">
                  {formExpErrors[index]?.marksPercentage}
                </div>
                <div className="required-field">
                  {formExpErrors[index]?.empProof}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label></label>
                {element.empProof ? (
                  <div className="card preview">
                    <object
                      data={`${baseUrl}${element.empProof}`}
                      type="application/pdf"
                      aria-label="document proof pdf"
                    ></object>

                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={() =>
                        handleDeleteFile("empProof", element.empProof, index)
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary btn-upload form-control"
                    onClick={() => handleUploadClick("empProof", index)}
                  >
                    Upload
                  </button>
                )}
              </div>
            </div>

            <div className="col-md-2">
              {index ? (
                <div className="form-group">
                  <label></label>
                  <button
                    type="button"
                    className="btn btn-danger form-control btn-upload"
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}

      <button
        className="button btn btn-primary"
        type="button"
        onClick={() => addFormFields()}
      >
        Add More
      </button>
      <div className="row">
        <div className="col-md-3">
          <div className="form-group">
            <label className="pt-4">
              Name of current organisation <span>*</span>
            </label>
            <input
              className="form-control"
              type="text"
              name="nameOfCurrentOrg"
              placeholder=""
              value={formData?.nameOfCurrentOrg}
              onChange={(e) => handleChange(e, null)}
            />
            <div className="required-field">{formErrors?.nameOfCurrentOrg}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>
              Type of Organisation <span>*</span>
            </label>
            <select
              name="typeOfOrg"
              className="form-control"
              id="typeOfOrg"
              defaultValue={""}
              value={formData?.typeOfOrg}
              onChange={(e) => handleChange(e, null)}
            >
              <option value="">-Select organisation type-</option>
              <option value="stateGovt">State Govt.</option>
              <option value="centralGovt">Central Govt.</option>
              <option value="psu">PSU</option>
              <option value="private">Private</option>
              <option value="other">Other</option>
            </select>
            <div className="required-field">{formErrors?.typeOfOrg}</div>
          </div>
        </div>
        <div className="col-md-6">
          <label>
            Overall Experience <span>*</span>
          </label>
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="overallExp"
              placeholder=""
              value={formData?.overallExp}
              disabled={true}
            />
            <div className="required-field">{formErrors?.overallExp}</div>
          </div>
        </div>
        <div className="row">
          {isPayScale ? (
            <>
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Pay Scale type? <span>*</span>
                  </label>
                  <div className="form-group">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payScaleType"
                        value={"CDA"}
                        checked={formData?.payScaleType === "CDA"}
                        onChange={(e) => handleChange(e, null)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="payScaleType1"
                      >
                        CDA
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="payScaleType"
                        value={"IDA"}
                        checked={formData?.payScaleType === "IDA"}
                        onChange={(e) => handleChange(e, null)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="payScaleType2"
                      >
                        IDA
                      </label>
                    </div>
                  </div>
                  <div className="required-field">
                    {formErrors?.payScaleType}
                  </div>
                </div>
              </div>
              {formData?.payScaleType && formData.payScaleType === "IDA" ? (
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Select your payscale <span>*</span>
                    </label>
                    <select
                      name="payScale"
                      defaultValue={""}
                      value={formData?.payScale}
                      className="form-control"
                      id="payScale"
                      onChange={(e) => {
                        handleChange(e, null);
                      }}
                    >
                      <option value="">-Select Payscale-</option>
                      <option value="29000-110000">29000-110000</option>
                      <option value="30000-120000">30000-120000</option>
                      <option value="40000-140000">40000-140000</option>
                      <option value="50000-160000">50000-160000</option>
                      <option value="60000-180000">60000-180000</option>
                      <option value="70000-200000">70000-200000</option>
                      <option value="80000-220000">80000-220000</option>
                      <option value="others">Others</option>
                    </select>
                    <div className="required-field">{formErrors?.payScale}</div>
                  </div>
                </div>
              ) : (
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Pay Scale<span>*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      name="payScale"
                      placeholder=""
                      value={formData?.payScale}
                      onChange={(e) => {
                        handleChange(e, null);
                      }}
                    />
                    <div className="required-field">{formErrors?.payScale}</div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="col-md-3">
              <div className="form-group">
                <label>
                  Current CTC <span>*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  name="currentCTC"
                  placeholder=""
                  value={formData?.currentCTC}
                  onChange={(e) => {
                    handleChange(e, null);
                  }}
                />
                <div className="required-field">{formErrors?.currentCTC}</div>
              </div>
            </div>
          )}

          <div className="col-md-4">
            <div className="form-group">
              <label>
                CTC Document Proof <span>*</span>
              </label>
              <input
                accept="application/pdf"
                className="form-control"
                type="file"
                name="ctcProof"
                id={`ctcProof`}
              />
              <div className="required-field">{formExpErrors?.ctcProof}</div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label></label>
              {formData?.ctcProof ? (
                <div className="card preview">
                  <object
                    data={`${baseUrl}${formData?.ctcProof}`}
                    type="application/pdf"
                    aria-label="document proof pdf"
                  ></object>

                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() =>
                      handleDeleteFile("ctcProof", formData?.ctcProof, null)
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary btn-upload form-control"
                  onClick={() => handleUploadClick("ctcProof", null)}
                >
                  Upload
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ul className="list-inline pull-right">
        <li>
          <button
            onClick={handleBackSteps}
            type="button"
            className="default-btn prev-step"
          >
            Back
          </button>
        </li>
        <li>
          <button
            onClick={HandleSubmit}
            type="button"
            className="default-btn next-step m-3"
          >
            Save & Next
          </button>
        </li>
      </ul>
    </div>
  );
};
