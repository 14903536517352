export const post = async (api, body) => {
  return await fetch(api, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "accept: application/json",
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const get = async (api) => {
  return await fetch(api, {
    headers: {
      Accept: "accept: application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const patch = async (api, body) => {
  return await fetch(api, {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: {
      Accept: "accept: application/json",
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

export const fileUpload = async (api, file) => {
  return await fetch(api, {
    method: "PUT",
    body: file,
    headers: {
      headers: {
        "content-type": "multipart/form-data",
        "content-length": `${file.size}`,
      },
      Authorization: getAccessToken(),
    },
  });
};

export const deleteUpload = async (api, file) => {
  return await fetch(api, {
    method: "DELETE",
    body: JSON.stringify({ filePath: file }),
    headers: {
      Accept: "accept: application/json",
      "Content-Type": "application/json",
      Authorization: getAccessToken(),
    },
  });
};

function getAccessToken() {
  const token = localStorage.getItem("accessToken");
  return `Bearer ${token}`;
}
