import React from 'react';
import "./PrivacyPolicy.css";

export const PrivacyPolicy = () => {
  return (
    <>
          <div className="container pt-3">
      <h4 className='pp-title mt-2'>Privacy Policy</h4>
      <div>
        <ol>
          <h5 className='pp-sub-title'>Introduction:</h5>
          <li>Welcome to HSCC Site. At HSCC Site, we take your privacy seriously and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you visit our website or use our services.
          </li>
        </ol>
      </div>
      <div>
        <ol type="a">
          <h5 className='pp-sub-title'>Information We Collect:</h5>
          <li>Personal Information: When you interact with ExampleSite, we may collect certain personally identifiable information, such as your name, email address, and phone number. You may provide this information voluntarily when you sign up for an account, subscribe to our newsletter, or contact us through the website.</li>
          <li> Non-Personal Information: We may also collect non-personal information, such as your IP address, browser type, device information, and website usage data. This information is collected automatically through cookies and similar technologies to enhance your user experience and improve our services.</li>
        </ol>
      </div>
      <div>
        <ol type="a">
          <h5 className='pp-sub-title'>Use of Collected Information:</h5>
          <li>
            Personal Information: We may use your personal information to provide you with our services, respond to your inquiries, send promotional materials, and improve our website's functionality. We will obtain your consent before using your data for purposes beyond the scope of this Privacy Policy.
          </li>
          <li>Non-Personal Information: Non-personal information is used to analyze website traffic, track user engagement, and optimize our website's performance.</li>
        </ol>
      </div>
      <div>
        <ol>
          <h5 className='pp-sub-title'>Legal Basis for Processing:</h5>
          <p>Our legal basis for processing your personal information is your consent, which you provide when you use our services or interact with our website. You have the right to withdraw your consent at any time by contacting us.</p>
        </ol>
      </div>
      <div>
        <ol>
          <h5 className='pp-sub-title'>Data Sharing:</h5>
          <p>We may share your personal information with trusted third-party service providers who assist us in operating our website and delivering our services. These service providers are bound by confidentiality obligations and are not allowed to use your information for other purposes.
          </p>
        </ol>
      </div>
      <div>
        <ol>
          <h5 className='pp-sub-title'>Data Security:
          </h5>
          <p>We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, or alteration.
          </p>
        </ol>
      </div>
      </div>
    </>
  )
}
