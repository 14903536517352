export const ageRelaxation = {
  E7_UR: 52,
  E7_EWS: 52,
  E7_OBC: 55,
  E7_SC: 57,
  E7_ST: 57,
  E7_PH: 62,
  E7_INT: 57,

  E6_UR: 49,
  E6_EWS: 49,
  E6_OBC: 52,
  E6_SC: 54,
  E6_ST: 54,
  E6_PH: 59,
  E6_INT: 54,

  E5_UR: 45,
  E5_EWS: 45,
  E5_OBC: 48,
  E5_SC: 50,
  E5_ST: 50,
  E5_PH: 55,
  E5_INT: 50,

  E4_UR: 41,
  E4_EWS: 41,
  E4_OBC: 44,
  E4_SC: 46,
  E4_ST: 46,
  E4_PH: 51,
  E4_INT: 46,

  E3_UR: 37,
  E3_EWS: 37,
  E3_OBC: 40,
  E3_SC: 42,
  E3_ST: 42,
  E3_PH: 47,
  E3_INT: 42,

  E2_UR: 33,
  E2_EWS: 33,
  E2_OBC: 36,
  E2_SC: 38,
  E2_ST: 38,
  E2_PH: 43,
  E2_INT: 38,

  E1_UR: 30,
  E1_EWS: 30,
  E1_OBC: 33,
  E1_SC: 35,
  E1_ST: 35,
  E1_PH: 40,
  E1_INT: 35,

  E0_UR: 28,
  E0_EWS: 28,
  E0_OBC: 31,
  E0_SC: 33,
  E0_ST: 33,
  E0_PH: 38,
  E0_INT: 33,

  E0T_UR: 26,
  E0T_EWS: 26,
  E0T_OBC: 29,
  E0T_SC: 31,
  E0T_ST: 31,
  E0T_PH: 26,
  E0T_INT: 0,

  S2_UR: 40,
  S2_EWS: 40,
  S2_OBC: 43,
  S2_SC: 45,
  S2_ST: 45,
  S2_PH: 50,
  S2_INT: 45,
};

export const ctcRequired = {
  /* ****************** MIN *************** */
  E7_stateGovt_MIN: 123100,
  E7_centralGovt_MIN: 123100,
  E7_psu_MIN: 123100,
  E7_private_MIN: 2500000,
  E7_other_MIN: 2500000,

  E6_stateGovt_MIN: 123100,
  E6_centralGovt_MIN: 123100,
  E6_psu_MIN: 123100,
  E6_private_MIN: 2200000,
  E6_other_MIN: 2200000,

  E5_stateGovt_MIN: 78800,
  E5_centralGovt_MIN: 78800,
  E5_psu_MIN: 78800,
  E5_private_MIN: 2000000,
  E5_other_MIN: 2000000,

  E4_stateGovt_MIN: 67700,
  E4_centralGovt_MIN: 67700,
  E4_psu_MIN: 67700,
  E4_private_MIN: 1800000,
  E4_other_MIN: 1800000,

  E3_stateGovt_MIN: 56100,
  E3_centralGovt_MIN: 56100,
  E3_psu_MIN: 56100,
  E3_private_MIN: 1500000,
  E3_other_MIN: 1500000,

  E2_stateGovt_MIN: 47600,
  E2_centralGovt_MIN: 47600,
  E2_psu_MIN: 47600,
  E2_private_MIN: 1200000,
  E2_other_MIN: 1200000,

  E1_stateGovt_MIN: 44900,
  E1_centralGovt_MIN: 44900,
  E1_psu_MIN: 44900,
  E1_private_MIN: 900000,
  E1_other_MIN: 900000,

  E0_stateGovt_MIN: 0,
  E0_centralGovt_MIN: 0,
  E0_psu_MIN: 0,
  E0_private_MIN: 0,
  E0_other_MIN: 0,

  E0T_stateGovt_MIN: 0,
  E0T_centralGovt_MIN: 0,
  E0T_psu_MIN: 0,
  E0T_private_MIN: 0,
  E0T_other_MIN: 0,

  S2_stateGovt_MIN: 0,
  S2_centralGovt_MIN: 0,
  S2_psu_MIN: 0,
  S2_private_MIN: 0,
  S2_other_MIN: 0,

  /* ****************** MAX *************** */

  E7_stateGovt_MAX: 215900,
  E7_centralGovt_MAX: 215900,
  E7_psu_MAX: 215900,
  E7_private_MAX: 10000000,
  E7_other_MAX: 10000000,

  E6_stateGovt_MAX: 215900,
  E6_centralGovt_MAX: 215900,
  E6_psu_MAX: 215900,
  E6_private_MAX: 10000000,
  E6_other_MAX: 10000000,

  E5_stateGovt_MAX: 209200,
  E5_centralGovt_MAX: 209200,
  E5_psu_MAX: 209200,
  E5_private_MAX: 10000000,
  E5_other_MAX: 10000000,

  E4_stateGovt_MAX: 208700,
  E4_centralGovt_MAX: 208700,
  E4_psu_MAX: 208700,
  E4_private_MAX: 10000000,
  E4_other_MAX: 10000000,

  E3_stateGovt_MAX: 177500,
  E3_centralGovt_MAX: 177500,
  E3_psu_MAX: 177500,
  E3_private_MAX: 10000000,
  E3_other_MAX: 10000000,

  E2_stateGovt_MAX: 151100,
  E2_centralGovt_MAX: 151100,
  E2_psu_MAX: 151100,
  E2_private_MAX: 10000000,
  E2_other_MAX: 10000000,

  E1_stateGovt_MAX: 142400,
  E1_centralGovt_MAX: 142400,
  E1_psu_MAX: 142400,
  E1_private_MAX: 10000000,
  E1_other_MAX: 10000000,

  E0_stateGovt_MAX: 10000000,
  E0_centralGovt_MAX: 10000000,
  E0_psu_MAX: 10000000,
  E0_private_MAX: 10000000,
  E0_other_MAX: 10000000,

  E0T_stateGovt_MAX: 10000000,
  E0T_centralGovt_MAX: 10000000,
  E0T_psu_MAX: 10000000,
  E0T_private_MAX: 10000000,
  E0T_other_MAX: 10000000,

  S2_stateGovt_MAX: 10000000,
  S2_centralGovt_MAX: 10000000,
  S2_psu_MAX: 10000000,
  S2_private_MAX: 10000000,
  S2_other_MAX: 10000000,
};

export const ctcIDARequired = {
  /* ****************** MIN *************** */
  E7_stateGovt_MIN: 90000,
  E7_centralGovt_MIN: 90000,
  E7_psu_MIN: 90000,
  E7_private_MIN: 2500000,
  E7_other_MIN: 2500000,

  E6_stateGovt_MIN: 80000,
  E6_centralGovt_MIN: 80000,
  E6_psu_MIN: 80000,
  E6_private_MIN: 2200000,
  E6_other_MIN: 2200000,

  E5_stateGovt_MIN: 70000,
  E5_centralGovt_MIN: 70000,
  E5_psu_MIN: 70000,
  E5_private_MIN: 2000000,
  E5_other_MIN: 2000000,

  E4_stateGovt_MIN: 60000,
  E4_centralGovt_MIN: 60000,
  E4_psu_MIN: 60000,
  E4_private_MIN: 1800000,
  E4_other_MIN: 1800000,

  E3_stateGovt_MIN: 50000,
  E3_centralGovt_MIN: 50000,
  E3_psu_MIN: 50000,
  E3_private_MIN: 1500000,
  E3_other_MIN: 1500000,

  E2_stateGovt_MIN: 40000,
  E2_centralGovt_MIN: 40000,
  E2_psu_MIN: 40000,
  E2_private_MIN: 1200000,
  E2_other_MIN: 1200000,

  E1_stateGovt_MIN: 30000,
  E1_centralGovt_MIN: 30000,
  E1_psu_MIN: 30000,
  E1_private_MIN: 30000,
  E1_other_MIN: 900000,

  E0_stateGovt_MIN: 0,
  E0_centralGovt_MIN: 0,
  E0_psu_MIN: 0,
  E0_private_MIN: 0,
  E0_other_MIN: 0,

  E0T_stateGovt_MIN: 0,
  E0T_centralGovt_MIN: 0,
  E0T_psu_MIN: 0,
  E0T_private_MIN: 0,
  E0T_other_MIN: 0,

  S2_stateGovt_MIN: 0,
  S2_centralGovt_MIN: 0,
  S2_psu_MIN: 0,
  S2_private_MIN: 0,
  S2_other_MIN: 0,

  /* ****************** MAX *************** */

  E7_stateGovt_MAX: 215900,
  E7_centralGovt_MAX: 215900,
  E7_psu_MAX: 240000,
  E7_private_MAX: 10000000,
  E7_other_MAX: 10000000,

  E6_stateGovt_MAX: 215900,
  E6_centralGovt_MAX: 215900,
  E6_psu_MAX: 220000,
  E6_private_MAX: 10000000,
  E6_other_MAX: 10000000,

  E5_stateGovt_MAX: 209200,
  E5_centralGovt_MAX: 209200,
  E5_psu_MAX: 200000,
  E5_private_MAX: 10000000,
  E5_other_MAX: 10000000,

  E4_stateGovt_MAX: 208700,
  E4_centralGovt_MAX: 208700,
  E4_psu_MAX: 180000,
  E4_private_MAX: 10000000,
  E4_other_MAX: 10000000,

  E3_stateGovt_MAX: 177500,
  E3_centralGovt_MAX: 177500,
  E3_psu_MAX: 160000,
  E3_private_MAX: 10000000,
  E3_other_MAX: 10000000,

  E2_stateGovt_MAX: 151100,
  E2_centralGovt_MAX: 151100,
  E2_psu_MAX: 140000,
  E2_private_MAX: 10000000,
  E2_other_MAX: 10000000,

  E1_stateGovt_MAX: 142400,
  E1_centralGovt_MAX: 142400,
  E1_psu_MAX: 120000,
  E1_private_MAX: 10000000,
  E1_other_MAX: 10000000,

  E0_stateGovt_MAX: 10000000,
  E0_centralGovt_MAX: 10000000,
  E0_psu_MAX: 10000000,
  E0_private_MAX: 10000000,
  E0_other_MAX: 10000000,

  E0T_stateGovt_MAX: 10000000,
  E0T_centralGovt_MAX: 10000000,
  E0T_psu_MAX: 10000000,
  E0T_private_MAX: 10000000,
  E0T_other_MAX: 10000000,

  S2_stateGovt_MAX: 10000000,
  S2_centralGovt_MAX: 10000000,
  S2_psu_MAX: 10000000,
  S2_private_MAX: 10000000,
  S2_other_MAX: 10000000,
};

export const expRequired = {
  E7: 23,
  E6: 20,
  E5: 18,
  E4: 14,
  E3: 10,
  E2: 7,
  E1: 4,
  E0: 2,
  E0T: 0,
  S2: 8,
};

export const enumPost = {
  civil: "Civil",
  electrical: "Electrical",
  mechenical: "Mechenical",
  it: "Information Technology",
  bioMed: "Bio Medical Engineer",
  hrm: "HRM",
  finance: "Finance & Accounts",
  corpCom: "Corporate Communication",
  pr: "PR",
  offLang: "Official Language",
  mkting: "Marketing",
  dMan: "D'Man",
  legal: "Legal",
  architecture: "Architecture",
  "it-architecture": "IT Architecture",
  "company-secretary": "Company Secretary",
  system: "System",
};

export const discipline = {
  HSCCRECT20241: {
    civil: "Civil",
    CorporateCoM: "Corporate Communication",
    electrical: "Electrical",
    mechenical: "Mechenical",
    BIOMEDICAL: "Bio Medical",
    finance: "Finance & Accounts",
    EnvironmentalEng: "Environmental Engineering",
    hrm: "HRM",
    legal: "Legal",
    companySecretary: "Company Secretary",
    system: "System",
    PHARMACY: "Pharmcy",
  },
  HSCCRECT20232: {
    civil: "Civil",
    electrical: "Electrical",
    mechenical: "Mechenical",
    architecture: "Architecture",
    finance: "Finance & Accounts",
    hrm: "HRM",
    "company-secretary": "Company Secretary",
    system: "System",
    legal: "Legal",
    "bio-med": "Bio Medical",
  },
  ALL: {
    civil: "Civil",
    mechenical: "Mechenical",
    architecture: "Architecture",
    finance: "Finance & Accounts",
    system: "System",
    legal: "Legal",
    BIOMEDICAL: "Bio Medical",
    CorporateCoM: "Corporate Communication",
    electrical: "Electrical",
    EnvironmentalEng: "Environmental Engineering",
    companySecretary: "Company Secretary",
    PHARMACY: "Pharmcy",
    hrm: "HRM",
  },
};

export const AdvertiseNumber = {
  HSCCRECT20241: "HSCCRECT20241",
  HSCCRECT20232: "HSCCRECT20232",
  HSCCRECT20231: "HSCCRECT20231",
};

export const level = {
  HSCCRECT20241: {
    E0: "E0",
    E2: "E2",
    E3: "E3",
    E4: "E4",
    E5: "E5",
  },
  HSCCRECT20232: {
    E0: "E0",
    E2: "E2",
    E3: "E3",
    E4: "E4",
  },
  ALL: {
    E0: "E0",
    E1: "E1",
    E2: "E2",
    E3: "E3",
    E4: "E4",
    E5: "E5",
    E6: "E6",
    E7: "E7",
  },
};

export const excelFields = [
  {
    name: "S.No.",
    selector: "sn",
  },
  {
    name: "Add Id",
    selector: "addId",
  },
  {
    name: "Post Applied",
    selector: "postApplied",
  },
  {
    name: "Level",
    selector: "level",
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
  },
  {
    name: "Contact Number",
    selector: "mobileNo",
  },
  {
    name: "Application Number",
    selector: "applicationNo",
  },
  {
    name: "Age",
    selector: "age",
  },
  {
    name: "Category",
    selector: "category",
  },
  {
    name: "Date of Birth",
    selector: "dob",
  },
  {
    name: "Essential Qualification",
    selector: "examPassed",
  },
  {
    name: "University",
    selector: "nameOfUniversity",
  },
  {
    name: "Year of Qualification",
    selector: "yearOfPassing",
  },
  {
    name: "Grade or CGPA or Percentage",
    selector: "marksPercentage",
  },
  {
    name: "Total Year of Exp",
    selector: "overallExp",
  },
  {
    name: "Current Org",
    selector: "nameOfCurrentOrg",
  },
  {
    name: "Status",
    selector: "status",
  },
  {
    name: "Pyment Status",
    selector: "paymentStatus",
  },
];
