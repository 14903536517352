import { useState } from "react";
import { useNavigate } from "react-router";
import { deleteUpload, fileUpload, patch } from "../services/api";
const baseUrl = process.env.REACT_APP_FILE_BASE_URL;
const oneMB = 1000000;

const api = process.env.REACT_APP_END_POINT;
/* eslint-disable import/no-anonymous-default-export */
export default ({
  applicationData,
  step,
  handleBackSteps,
  handleNextSteps,
}) => {
  const navigate = useNavigate();
  const data = applicationData;
  const [formData, setFormData] = useState(data);
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "text" || e.target.type === "textarea") {
      value = value.toUpperCase();
    }

    setFormData({ ...formData, [name]: value });
    setFormErrors("");
  };

  const formValidate = (val) => {
    const error = { success: true };
    /*  if (val.isApplied === "Yes") {
      if (!val.postAppliedFor) {
        error.postAppliedFor = "Please enter applied post";
        error.success = false;
      }

      if (!val.monthYear) {
        error.monthYear = "Please select a month and year of application";
        error.success = false;
      }

      if (!val.isInterviewAttended) {
        error.isInterviewAttended = "Please select one";
        error.success = false;
      }
    } */
    if (val.haveWorkedAtHSCC === "Yes") {
      if (!val.startDate) {
        error.startDate = "Please select when you started with hscc";
        error.success = false;
      }
      if (!val.endDate) {
        error.endDate = "Please select till you have worked with hscc";
        error.success = false;
      }
      if (!val.postHeld) {
        error.postHeld = "Please enter which post is held at hscc";
        error.success = false;
      }
    }
    if (val.isRelativeWorkAtHSCC === "Yes") {
      if (!val.relativeName) {
        error.relativeName = "Please enter relative name";
        error.success = false;
      }
      if (!val.relativeDesignation) {
        error.relativeDesignation = "Please enter relative designation";
        error.success = false;
      }
    }

    /*   if (val.underWorked === "Yes") {
      if (!val.nameOfEmp) {
        error.nameOfEmp =
          "Please enter name of employee under whom you have worked";
        error.success = false;
      }
      if (!val.nameOfEmpOrg) {
        error.nameOfEmpOrg =
          "Please enter name of organisation under whom you have worked";
        error.success = false;
      }
    } */

    /*    if (!val.isApplied) {
      error.isApplied = "Please select one";
      error.success = false;
    } */
    if (!val.haveWorkedAtHSCC) {
      error.haveWorkedAtHSCC = "Please select one";
      error.success = false;
    }

    if (!val.isRelativeWorkAtHSCC) {
      error.isRelativeWorkAtHSCC = "Please select one";
      error.success = false;
    }

    /*    if (!val.underWorked) {
      error.underWorked = "Please select one";
      error.success = false;
    } */
    /*    if (!val.computerProficiency) {
      error.computerProficiency = "Please provide computer proficiency";
      error.success = false;
    } */

    if (!val.signature) {
      error.signature = "Please upload your signature image";
      error.success = false;
    }

    if (!val.declaration1) {
      error.declaration1 = "Please accept declaration";
      error.success = false;
    }

    if (!val.declaration2) {
      error.declaration2 = "Please accept declaration";
      error.success = false;
    }

    return error;
  };

  const handleUploadClick = async (fieldName) => {
    let file = null;
    const docEle = document.getElementById(fieldName);
    file = docEle.files[0];
    if (!file) return;
    if (fieldName === "otherDocProof" && file.size > oneMB) {
      alert("File size should be less then 1mb");
      docEle.value = "";
      return;
    }
    const data = new FormData();
    data.append(fieldName, file);
    fileUpload(`${api}accounts/upload-file`, data)
      .then((res) => res.json())
      .then((fileUploadResp) => {
        let filePath = fileUploadResp.filePath;
        setFormData((prev) => {
          return { ...prev, [fieldName]: filePath };
        });
      })

      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteFile = async (filePath, fieldName) => {
    deleteUpload(`${api}accounts/delete-file`, filePath)
      .then((fileDeleteResp) => {
        console.log("File Deleted");
        setFormData((prev) => {
          return { ...prev, [fieldName]: "" };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  async function HandleSubmit() {
    const validate = formValidate(formData);
    setFormErrors(validate);
    if (validate.success) {
      const reqBody = {
        /*   isApplied: formData.isApplied,
        postAppliedFor: formData.postAppliedFor,
        monthYear: formData.monthYear,
        isInterviewAttended: formData.isInterviewAttended, */
        haveWorkedAtHSCC: formData.haveWorkedAtHSCC,
        startDate: formData.startDate,
        endDate: formData.endDate,
        postHeld: formData.postHeld,
        isRelativeWorkAtHSCC: formData.isRelativeWorkAtHSCC,
        relativeName: formData.relativeName,
        relativeDesignation: formData.relativeDesignation,
        /*  underWorked: formData.underWorked,
        nameOfEmp: formData.nameOfEmp,
        nameOfEmpOrg: formData.nameOfEmpOrg, */
        // anyAchievement: formData.anyAchievement,
        // relevantInformation: formData.relevantInformation,
        // computerProficiency: formData.computerProficiency,
        // otherDocProof: formData.otherDocProof,
        signature: formData.signature,
      };
      let applicationId = localStorage.getItem("applicationId");
      await patch(`${api}application/other-info/${applicationId}`, reqBody);
      handleNextSteps();
      navigate("/preview");
    }
  }

  return (
    <div
      className={`tab-pane ${step === 4 ? "active" : ""}`}
      role="tabpanel"
      id="step4"
    >
      <h4 className="text-center step-head">
        <u> Other Info</u>
      </h4>

      {/*   <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>
              Have you applied earlier in HSCC during last two years?{" "}
              <span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isApplied"
                  value={"Yes"}
                  checked={formData.isApplied === "Yes"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="isApplied1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isApplied"
                  value={"No"}
                  checked={formData.isApplied === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="isApplied2">
                  No
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.isApplied}</div>
          </div>
        </div>
      </div> */}
      {/* <h1>-------------show this field on yes---------------</h1> */}

      {/*     {formData.isApplied === "Yes" ? (
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>
                Post applied for <span>*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="postAppliedFor"
                placeholder=""
                value={formData.postAppliedFor}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.postAppliedFor}</div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>
                Applied Month & Year<span>*</span>
              </label>
              <input
                className="form-control"
                type="date"
                name="monthYear"
                placeholder=""
                value={formData.postAppliedFor}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.monthYear}</div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>
                Whether attended interview?<span>*</span>
              </label>
              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isInterviewAttended"
                    value={"Yes"}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="isInterviewAttended1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isInterviewAttended"
                    value={"No"}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="isInterviewAttended2"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="required-field">{""}</div>
            </div>
            <div className="required-field">
              {formErrors.isInterviewAttended}
            </div>
          </div>
        </div>
      ) : null} */}
      {/* <h1> ---------------end--------------</h1> */}
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>Have you worked earlier with hscc?</label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="haveWorkedAtHSCC"
                  value={"Yes"}
                  checked={formData.haveWorkedAtHSCC === "Yes"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="haveWorkedAtHSCC1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="haveWorkedAtHSCC"
                  value={"No"}
                  checked={formData.haveWorkedAtHSCC === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="haveWorkedAtHSCC2">
                  No
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.haveWorkedAtHSCC}</div>
          </div>
        </div>
      </div>
      {/* <h1>-------------show this field on yes---------------</h1> */}
      {formData.haveWorkedAtHSCC === "Yes" ? (
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>
                Period From <span>*</span>
              </label>
              <input
                className="form-control"
                type="date"
                max="2023-08-31"
                name="startDate"
                placeholder=""
                value={formData.startDate}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.startDate}</div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>
                Till <span>*</span>
              </label>
              <input
                className="form-control"
                type="date"
                max="2023-08-31"
                name="endDate"
                placeholder=""
                value={formData.endDate}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.endDate}</div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>
                Post held <span>*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="postHeld"
                placeholder=""
                value={formData.postHeld}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.postHeld}</div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <h1> ---------------end--------------</h1> */}
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>
              Do you have any relative working with hscc?<span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isRelativeWorkAtHSCC"
                  value={"Yes"}
                  checked={formData.isRelativeWorkAtHSCC === "Yes"}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="isRelativeWorkAtHSCC1"
                >
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="isRelativeWorkAtHSCC"
                  value={"No"}
                  checked={formData.isRelativeWorkAtHSCC === "No"}
                  onChange={handleChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="isRelativeWorkAtHSCC2"
                >
                  No
                </label>
              </div>
            </div>
            <div className="required-field">
              {formErrors.isRelativeWorkAtHSCC}
            </div>
          </div>
        </div>
      </div>
      {/* <h1>-------------show this field on yes---------------</h1> */}
      {formData.isRelativeWorkAtHSCC === "Yes" ? (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Name of relative</label>
              <input
                className="form-control"
                type="text"
                name="relativeName"
                placeholder=""
                value={formData.relativeName}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.relativeName}</div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Designation of relative</label>
              <input
                className="form-control"
                type="text"
                name="relativeDesignation"
                placeholder=""
                value={formData.relativeDesignation}
                onChange={handleChange}
              />
              <div className="required-field">
                {formErrors.relativeDesignation}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <h1> ---------------end--------------</h1> */}
      {/*     <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>
              Do you know anyone in HSCC under whom you have worked earliar in
              any organisation?<span>*</span>
            </label>
            <div className="form-group">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="underWorked"
                  value={"Yes"}
                  checked={formData.underWorked === "Yes"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="underWorked1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="underWorked"
                  value={"No"}
                  checked={formData.underWorked === "No"}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="underWorked2">
                  No
                </label>
              </div>
            </div>
            <div className="required-field">{formErrors.underWorked}</div>
          </div>
        </div>
      </div> */}
      {/* <h1>-------------show this field on yes---------------</h1> */}
      {/*    {formData.underWorked === "Yes" ? (
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Name of Employee <span>*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="nameOfEmp"
                placeholder=""
                value={formData.nameOfEmp}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.nameOfEmp}</div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>
                Name of organisation <span>*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="nameOfEmpOrg"
                placeholder=""
                value={formData.nameOfEmpOrg}
                onChange={handleChange}
              />
              <div className="required-field">{formErrors.nameOfEmpOrg}</div>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* <h1> ---------------end--------------</h1> */}
      {/*    <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Details of Computer proficiency<span>*</span>
            </label>
            <textarea
              className="form-control"
              type="text"
              name="computerProficiency"
              placeholder="Advance / Basic"
              cols="30"
              rows="2"
              value={formData?.computerProficiency}
              onChange={(e) => handleChange(e, null)}
            ></textarea>
            <div className="required-field">
              {formErrors?.computerProficiency}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Achievement, if any</label>
            <input
              className="form-control"
              type="text"
              name="anyAchievement"
              placeholder=""
              value={formData.anyAchievement}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Other relevant information or document, if any</label>
            <textarea
              className="form-control"
              type="text"
              name="relevantInformation"
              placeholder=""
              value={formData.relevantInformation}
              onChange={handleChange}
              id=""
              cols="30"
              rows="2"
            ></textarea>
          </div>
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label>Upload other relevant information document, if any </label>
            <input
              accept="application/pdf, image/png, image/jpeg, image/jpg"
              className="form-control"
              type="file"
              name="otherDocProof"
              id="otherDocProof"
            />
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            {formData?.otherDocProof ? (
              <div className="card preview">
                <object
                  data={`${baseUrl}${formData?.otherDocProof}`}
                  type="application/pdf"
                  aria-label="aa proof pdf"
                ></object>

                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    handleDeleteFile(formData.otherDocProof, "otherDocProof")
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="form-control btn btn-secondary btn-upload"
                onClick={() => handleUploadClick("otherDocProof")}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label>
              Signature <span>*</span>{" "}
            </label>
            <input
              accept="image/png, image/jpeg, image/jpg"
              className="form-control"
              type="file"
              name="signature"
              id="signature"
            />
            <div className="required-field">{formErrors?.signature}</div>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            {formData?.signature ? (
              <div className="card preview">
                <img
                  height={100}
                  width={100}
                  src={`${baseUrl}${formData?.signature}`}
                  alt="signature"
                />
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() =>
                    handleDeleteFile(formData.signature, "signature")
                  }
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            ) : (
              <button
                type="button"
                className="form-control btn btn-secondary btn-upload"
                onClick={() => handleUploadClick("signature")}
              >
                Upload
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="declaration1"
              value={"declaration1"}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="defaultCheck1">
              I hereby declare that I have relevant experience as mentioned in
              the advertisement for the post applied by me.
            </label>
          </div>
          <div className="required-field">{formErrors?.declaration1}</div>
        </div>
        <div className="col-12 mb-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="declaration2"
              value={"declaration2"}
              onChange={handleChange}
            />
            <label>
              I hereby declare that all the information given/statements made by
              me in the application are true, complete and correct to the best
              of my knowledge and belief and nothing has been concealed or
              surpressed. In the event of any information being found false or
              incorrect or is not in conformity with the eligibility criteria
              mentioned in the advertisement at any point of time my
              candidature/ appointment may be cancelled/terminated without any
              notice and/or i shall be liable for any other action under the
              extant rules.
            </label>
          </div>
          <div className="required-field">{formErrors?.declaration2}</div>
        </div>
      </div>

      <ul className="list-inline pull-right">
        <li>
          <button
            onClick={handleBackSteps}
            type="button"
            className="default-btn prev-step"
          >
            Back
          </button>
        </li>
        <li>
          <button
            onClick={HandleSubmit}
            type="button"
            className="default-btn next-step m-3"
          >
            Submit
          </button>
        </li>
      </ul>
    </div>
  );
};
