import React from "react";
import "./ServiceDetailsWithPricingStruct.css";

export const ServiceDetailsWithPricingStruct = () => {
  return (
    <>
      <div className="container pt-3">
        <h4 className="sdwps-title mt-4">
          Service Details With Pricing Structure
        </h4>
        <h5 className="sdwps-sub-title">PAYMENT OF APPLICATION FEE:</h5>
        <ul>
          <p>
            {" "}
            &bull; Applicants/ Candidates are required to pay non-refundable fee
            of &#8377; 1000/- through Online gateway.
          </p>

          <p>
            {" "}
            &bull; Candidates will be levied tax/charge from their transaction
            Bank as applicable.
          </p>

          <p>
            {" "}
            &bull; SC/ ST/ PWD and Internal candidates will be exempted from
            payment of Application Fee.
          </p>

          <p>
            {" "}
            &bull; Candidates are required to make Application Fee payment
            through debit card/credit card/ net-banking. The candidate will be
            automatically redirected to make online payment, wherever the fee is
            applicable, once the form is successfully completed.
          </p>

          <p>
            {" "}
            &bull; No other mode of payment will be accepted. Application fee
            once paid will not be refunded under any circumstances. Candidates
            are therefore requested to verify their eligibility before payment
            of Application Fee.
          </p>
        </ul>
      </div>
    </>
  );
};
