import React from "react";
import "./RefundAndCancellation.css";

export const RefundAndCancellation = () => {
  return (
    <>
      <div className="container pt-3">
        <h4 className="rac-title mt-2">Refund And Cancellation</h4>
        <h5 className="rac-sub-title">PROCEDURE FOR APPLYING: -</h5>
        <ul>
          <p>
            The candidates are required to read the General Instructions
            mentioned below before filling up the Online Application Form. The
            candidates are required to apply online. The relevant link for the
            online application will be made available under the head
            &ldquo;CAREER&rdquo; on HSCC website, i.e., www.hsccltd.co.in.
          </p>

          <p>
            Before filling application online, candidates should keep ready
            scanned copy of passport size photograph &amp; signature in
            .jpg/.jpeg format (photo size less than 300kb and signature size
            less than 200kb) and other relevant documents in .pdf format less
            than 1mb per document and a valid e-mail ID in operation for more
            than one year. Fill in the online form with relevant details. Upload
            scanned copy of the photograph &amp; signature. Candidates should
            ensure that the relevant details viz. Name, Date of birth, Address,
            etc. entered in HSCC online application is correct. Detailed
            procedure regarding payment of fee is explained under &ldquo;PAYMENT
            OF APPLICATION FEE.&rdquo;
          </p>
        </ul>

        <h5 className="rac-sub-title">PAYMENT OF APPLICATION FEE: -</h5>
        <ul>
          <p>
            {" "}
            &bull; Applicants/ Candidates are required to pay non-refundable fee
            of &#8377; 1000/- through Online gateway.
          </p>

          <p>
            {" "}
            &bull; Candidates will be levied tax/charge from their transaction
            Bank as applicable.
          </p>

          <p>
            {" "}
            &bull; SC/ ST/ PWD and Internal candidates will be exempted from
            payment of Application Fee.
          </p>

          <p>
            {" "}
            &bull; Candidates are required to make Application Fee payment
            through debit card/credit card/ net-banking. The candidate will be
            automatically redirected to make online payment, wherever the fee is
            applicable, once the form is successfully completed.
          </p>

          <p>
            {" "}
            &bull; No other mode of payment will be accepted. Application fee
            once paid will not be refunded under any circumstances. Candidates
            are therefore requested to verify their eligibility before payment
            of Application Fee.
          </p>
        </ul>
      </div>
    </>
  );
};
