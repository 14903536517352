/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { deleteUpload, fileUpload, get, patch } from "../services/api";
import { toast } from "react-toastify";
const api = process.env.REACT_APP_END_POINT;
const baseUrl = process.env.REACT_APP_FILE_BASE_URL;
/* eslint-disable import/no-anonymous-default-export */
export default ({
  step,
  handleBackSteps,
  handleNextSteps,
  applicationData,
}) => {
  const oneMB = 1000000;
  const addMore = {
    examPassed: "",
    specialization: "",
    typeOfCourse: "",
    durationOfCourse: "",
    yearOfPassing: "",
    nameOfUniversity: "",
    maxMarks: "",
    marksObtained: "",
    marksPercentage: "",
    documentProof: "",
  };
  const qualificationInitilaValue = [
    {
      examPassed: "",
      specialization: "",
      typeOfCourse: "",
      durationOfCourse: "",
      yearOfPassing: "",
      nameOfUniversity: "",
      maxMarks: "",
      marksObtained: "",
      marksPercentage: "",
      documentProof: "",
    },
    {
      examPassed: "10TH",
      yearOfPassing: "",
      nameOfUniversity: "",
      maxMarks: "",
      marksObtained: "",
      marksPercentage: "",
      documentProof: "",
    },
    {
      examPassed: "12TH",
      yearOfPassing: "",
      nameOfUniversity: "",
      maxMarks: "",
      marksObtained: "",
      marksPercentage: "",
      documentProof: "",
    },
  ];

  const data = applicationData;
  const [otherFormData, setotherFormData] = useState(data);
  const [formData, setFormData] = useState([]);
  const [formErrors, setFormErrors] = useState({});

  const qualifications =
    data?.qualifications && data?.qualifications.length > 0
      ? data?.qualifications
      : [...qualificationInitilaValue];

  useEffect(() => {
    setFormData([...qualifications]);
  }, []);

  useMemo(() => {
    const acontroler = new AbortController();
    async function getData() {
      try {
        let data = {};
        let applicationId = localStorage.getItem("applicationId");
        if (applicationId) {
          let response = await get(`${api}application/get/${applicationId}`);
          const status = response.status;
          if (status >= 400) throw new Error("Somthing went wrong");
          response = await response.json();
          data = response.data;
        }
        setotherFormData({ ...data });
      } catch (error) {
        toast.error(error.message);
      }
    }
    if (step === 2) getData();
    return () => {
      acontroler.abort();
    };
  }, [step]);

  let addFormFields = () => {
    setFormData([...formData, addMore]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formData];
    newFormValues.splice(i, 1);
    setFormData(newFormValues);
  };

  const handleChange = (e, index) => {
    let { name, value } = e.target;
    if (e.target.type === "text") {
      value = value.toUpperCase();
    }
    const formArr = [...formData];
    formArr[index][name] = value;
    setFormData(formArr);
    setFormErrors("");
  };

  const getMarksPercentage = (e, index) => {
    const formArr = [...formData];
    let maxMarks = formArr[index].maxMarks;
    let obtMarks = formArr[index].marksObtained;
    formArr[index].marksPercentage = ((obtMarks / maxMarks) * 100).toFixed(2);
    setFormData(formArr);
  };

  const handleUploadClick = async (index) => {
    const docEle = document.getElementById("documentProof" + index);
    const proofDocument = docEle.files[0];
    if (!proofDocument) return;

    if (proofDocument.size > oneMB) {
      alert("File size should be less then 1mb");
      docEle.value = "";
      return;
    }
    const data = new FormData();
    data.append("documentProof", proofDocument);
    fileUpload(`${api}accounts/upload-file`, data)
      .then((res) => res.json())
      .then((fileUploadResp) => {
        console.log("File Uploaded");
        let filePath = fileUploadResp.filePath;
        setFormData((prev) => {
          const formArr = [...prev];
          formArr[index]["documentProof"] = filePath;
          return formArr;
        });
      })

      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeleteFile = async (filePath, index) => {
    deleteUpload(`${api}accounts/delete-file`, filePath)
      .then((fileDeleteResp) => {
        console.log("File Deleted");
        setFormData((prev) => {
          const formArr = [...prev];
          formArr[index]["documentProof"] = "";
          return formArr;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formValidate = (val) => {
    const errorArr = [];
    for (let i = 0; i < val.length; i++) {
      const error = { success: true };
      if (
        i === 0 &&
        otherFormData.postApplied !== "finance" &&
        otherFormData.postApplied !== "company-secretary" &&
        otherFormData.postApplied !== "companySecretary" &&
        otherFormData.postApplied !== "legal"
      ) {
        if (val[i].marksPercentage < 60) {
          error.marksPercentage =
            "Your essential qualification marks % should be greater then 60%";
          error.success = false;
        }
      }
      if (!val[i].examPassed) {
        error.examPassed = "Please enter details";
        error.success = false;
      }
      if (!val[i].yearOfPassing) {
        error.yearOfPassing = "Please select year of passing";
        error.success = false;
      }
      if (!val[i].nameOfUniversity) {
        error.nameOfUniversity = "Please enter name of university";
        error.success = false;
      }
      if (!val[i].maxMarks) {
        error.maxMarks = "Maximum marks is required";
        error.success = false;
      }

      if (!val[i].marksObtained) {
        error.marksObtained = "Marks obtained is required";
        error.success = false;
      }
      if (!val[i].marksPercentage) {
        error.marksPercentage = "Marks % is required";
        error.success = false;
      }

      if (!val[i].documentProof) {
        error.documentProof = "Documnet proof is required";
        error.success = false;
      }
      errorArr.push(error);
    }
    // return { success: true };
    return errorArr;
  };

  async function HandleSubmit() {
    const validates = formValidate(formData);
    setFormErrors(validates);
    let submit = [];
    for (let validate of validates) {
      submit.push(validate.success);
    }
    if (!submit.includes(false)) {
      const reqBody = formData;
      let applicationId = localStorage.getItem("applicationId");
      let registerData = await patch(
        `${api}application/qualifications/${applicationId}`,
        reqBody
      );
      await registerData.json();
      handleNextSteps();
    }
  }
  let yearOptions = [<option value="">--Select Passing Year--</option>];
  let currentYear = new Date().getFullYear();
  let earliestYear = 1970;
  while (earliestYear < currentYear) {
    currentYear--;
    yearOptions.push(<option value={currentYear}>{currentYear}</option>);
  }
  return (
    <div
      className={`tab-pane ${step === 2 ? "active" : ""}`}
      role="tabpanel"
      id="step2"
    >
      <h4 className="text-center step-head">
        <u>Qualification Details</u>
      </h4>

      {formData.map((element, index) => (
        <>
          {index === 0 ? <h2>Essential Qualification</h2> : null}
          <div className="card mb-5 p-3" key={index}>
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    {index === 0
                      ? "Essential Qualification"
                      : "Name of Examination"}
                    <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="examPassed"
                    value={element.examPassed}
                    onChange={(e) => handleChange(e, index)}
                    placeholder=""
                  />
                  <div className="required-field">
                    {formErrors[index]?.examPassed}
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    Date of Acquiring Qualification <span>*</span>
                  </label>
                  {/* <select
                    className="form-control"
                    name="yearOfPassing"
                    value={element.yearOfPassing}
                    defaultValue={""}
                    onChange={(e) => handleChange(e, index)}
                  >
                    {yearOptions}
                  </select> */}

                  <input
                    className="form-control"
                    type="date"
                    max="2024-04-20"
                    maxLength={8}
                    name="yearOfPassing"
                    value={element.yearOfPassing}
                    onChange={(e) => handleChange(e, index)}
                    placeholder=""
                  />

                  <div className="required-field">
                    {formErrors[index]?.yearOfPassing}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Name of the Institute/University <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="nameOfUniversity"
                    value={element.nameOfUniversity}
                    placeholder=""
                    onChange={(e) => handleChange(e, index)}
                  />
                  <div className="required-field">
                    {formErrors[index]?.nameOfUniversity}
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    Max Marks/CGPA <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="maxMarks"
                    value={element.maxMarks}
                    placeholder=""
                    onChange={(e) => {
                      handleChange(e, index);
                      getMarksPercentage(e, index);
                    }}
                  />
                  <div className="required-field">
                    {formErrors[index]?.maxMarks}
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    Marks Obtained/CGPA <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="marksObtained"
                    value={element.marksObtained}
                    placeholder=""
                    onChange={(e) => {
                      handleChange(e, index);
                      getMarksPercentage(e, index);
                    }}
                  />
                  <div className="required-field">
                    {formErrors[index]?.marksObtained}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    % of marks <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    name="marksPercentage"
                    value={element.marksPercentage}
                    placeholder=""
                    disabled={true}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                  />
                  <div className="required-field">
                    {formErrors[index]?.marksPercentage}
                  </div>
                </div>
              </div>

              {index === 0 ? (
                <>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        Specialization <span>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="specialization"
                        value={element.specialization}
                        placeholder=""
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div className="required-field">
                        {formErrors[index]?.specialization}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2">
                    <div className="form-group">
                      <label>
                        Duration of course<span>*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="durationOfCourse"
                        value={element.durationOfCourse}
                        placeholder=""
                        onChange={(e) => handleChange(e, index)}
                      />
                      <div className="required-field">
                        {formErrors[index]?.durationOfCourse}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        Type Of Course?<span>*</span>
                      </label>
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="typeOfCourse"
                            id="typeOfCourse1"
                            onChange={(e) => handleChange(e, index)}
                            value={"fullTime"}
                            checked={element?.typeOfCourse === "fullTime"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="typeOfCourse1"
                          >
                            Full Time
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="typeOfCourse"
                            id="typeOfCourse2"
                            onChange={(e) => handleChange(e, index)}
                            value={"partTime"}
                            checked={element?.typeOfCourse === "partTime"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="typeOfCourse2"
                          >
                            Part Time
                          </label>
                        </div>
                      </div>
                      <div className="required-field">
                        {formErrors[index]?.typeOfCourse}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="col-md-4">
                <div className="form-group">
                  <label>
                    Document Proof <span>*</span>
                  </label>
                  <input
                    accept="application/pdf"
                    className="form-control"
                    type="file"
                    name="documentProof"
                    id={`documentProof${index}`}
                  />
                  <div className="required-field">
                    {formErrors[index]?.documentProof}
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label></label>
                  {element.documentProof ? (
                    <div className="card preview">
                      <object
                        data={`${baseUrl}${element.documentProof}`}
                        type="application/pdf"
                        aria-label="document proof pdf"
                      ></object>

                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={() =>
                          handleDeleteFile(element.documentProof, index)
                        }
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-secondary btn-upload form-control"
                      onClick={() => handleUploadClick(index)}
                    >
                      Upload
                    </button>
                  )}
                  <div className="required-field">{formErrors.postApplied}</div>
                </div>
              </div>

              <div className="col-md-2">
                {index > 2 ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => removeFormFields(index)}
                  >
                    Remove
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ))}

      <button
        className="button btn btn-primary"
        type="button"
        onClick={() => addFormFields()}
      >
        Add More
      </button>

      <ul className="list-inline pull-right">
        <li>
          <button
            onClick={handleBackSteps}
            type="button"
            className="default-btn prev-step"
          >
            Back
          </button>
        </li>
        <li>
          <button
            onClick={HandleSubmit}
            type="button"
            className="default-btn next-step m-3"
          >
            Save & Next
          </button>
        </li>
      </ul>
    </div>
  );
};
