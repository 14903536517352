import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Modal, Button, Form } from "react-bootstrap";
import { post } from "../services/api";
import { useNavigate } from "react-router";
import * as moment from "moment";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { discipline, excelFields, level, AdvertiseNumber } from "../services/helper";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import SearchIcon from "@mui/icons-material/Search";
const api = process.env.REACT_APP_END_POINT;

const AppList = () => {
  const navigate = useNavigate();
  const [getData, setData] = useState([]);
  const [getTotalRow, setTotalRow] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [isOpen, setOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [actionForm, setActionForm] = useState({});
  const [filterData, setFilterData] = useState(
    JSON.parse(sessionStorage.getItem('filterData')) || {}
  );

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const componentRef = useRef();

  const fetchApplicationList = async (page = 1, limit = perPage) => {
    try {
      setLoading(true);
      let body = filterData || {};
      if (page === null) {
        page = 1;
        body = {};
      }
      let response = await post(
        `${api}application/list?page=${page}&limit=${limit}`,
        body
      );
      const status = response.status;
      response = await response.json();
      if (status === 401) {
        navigate("/");
        throw new Error("Unauthorized");
      }
      if (status >= 400) throw new Error("Something went wrong");
      const data = response.data.applicationsList;
      const totalRow = response.data.total;
      setData(data);
      setTotalRow(totalRow);
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      return [];
    }
  };

  const handleChangePage = async (page) => {
    setCurrentPage(page);
    await fetchApplicationList(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    await fetchApplicationList(page, newPerPage);
  };

  const view = useCallback(
    (row) => async () => {
      const applicationId = row._id;
      const url = `/admin/users-form?applicationId=${applicationId}`;
      window.open(url, '_blank');
    },
    []
  );

  const action = useCallback(
    (row) => async () => {
      setFormData(row);
      setActionForm({ applicationId: row._id });
      openModal();
    },
    []
  );

  const onFilter = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => {
      const updatedFilters = { ...prev, [name]: value };
      sessionStorage.setItem('filterData', JSON.stringify(updatedFilters));
      return updatedFilters;
    });
  };

  const onSearch = async () => {
    setCurrentPage(1);
    await fetchApplicationList(1, perPage);
  };

  const onClearSearch = async () => {
    setFilterData({});
    sessionStorage.removeItem('filterData');
    setCurrentPage(1);
    await fetchApplicationList(1, perPage);
  };

  useEffect(() => {
    fetchApplicationList(currentPage, perPage);
  }, [currentPage, perPage]);

  const columns = useMemo(
    () => [
      {
        name: "Sr. No.",
        cell: (row, index) => (currentPage - 1) * perPage + index + 1,
      },
      {
        name: "Post Applied",
        selector: (row) => row.postApplied,
      },
      {
        name: "Level",
        selector: (row) => row.level,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Mobile",
        selector: (row) => row.mobileNo,
      },
      {
        name: "Email",
        selector: (row) => row.email,
      },
      {
        name: "Age",
        selector: (row) => row.age,
      },
      {
        name: "DOB",
        selector: (row) => row.dob,
      },
      {
        name: "EWS",
        selector: (row) => row.isPH,
      },
      {
        name: "Essential Qualification",
        selector: (row) => row.examPassed,
      },
      {
        name: "University",
        selector: (row) => row.nameOfUniversity,
      },
      {
        name: "Year of Qualification",
        selector: (row) => row.yearOfPassing,
      },
      {
        name: "% or Grade or CGPA",
        selector: (row) => row.marksPercentage,
      },
      {
        name: "Total Year of Exp",
        selector: (row) => row.overallExp,
      },
      {
        name: "Current Org",
        selector: (row) => row.nameOfCurrentOrg,
      },
      {
        name: "Payment Status",
        selector: (row) => row.paymentStatus,
      },
      {
        name: "Applied On",
        selector: (row) => row.updatedAt,
        sortable: true,
        format: (row) => moment(row.updatedAt).format("DD-MMM-YYYY"),
      },
      {
        name: "Action",
        button: true,
        cell: (row) => (
          <>
            <button className="btn btn-secondary m-1" onClick={view(row)}>
              <RemoveRedEyeIcon />
            </button>
            <button className="btn btn-secondary m-1" onClick={action(row)}>
              <ModeEditIcon />
            </button>
          </>
        ),
      },
    ],
    [action, view, currentPage, perPage]
  );
  

  async function convertArrayOfObjectsToCSV() {
    let [perPage, currentPage] = componentRef.current.value.split(",");
    let array = await fetchApplicationList(currentPage, perPage);
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    let keys = [];
    let selectors = [];
    excelFields.forEach((item) => {
      keys.push(item.name);
      selectors.push(item.selector);
    });

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach((item, index) => {
      let ctr = 0;
      selectors.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        if (key === "sn") {
          result += index + 1;
        } else {
          result += item[key] ? item[key] : "";
        }
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  async function downloadCSV() {
    const link = document.createElement("a");
    let csv = await convertArrayOfObjectsToCSV();
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const handleFormChange = (e) => {
    let { name, value } = e.target;
    setActionForm({ ...actionForm, [name]: value });
  };

  const formValidate = (val) => {
    const error = { success: true };
    if (!val.action) {
      error.action = "Please select";
      error.success = false;
    }
    if (val.action === "rejected" || val.action === "hold") {
      if (!val.remarks) {
        error.remarks = "Please fill the remarks";
        error.success = false;
      }
    }
    return error;
  };

  const onSubmit = async () => {
    const validate = formValidate(actionForm);
    setFormErrors(validate);
    if (validate.success) {
      const reqBody = {
        applicationId: actionForm.applicationId,
        action: actionForm.action,
        remarks: actionForm.remarks,
      };
      let response = await post(`${api}application/status/update`, reqBody);
      const status = response.status;
      if (status >= 400) throw new Error("Something went wrong");
      response = await response.json();
      closeModal();
      toast.success("Status changed successfully");
    }
  };

  return (
    <>
      <Modal show={isOpen}>
        <Modal.Header closeButton>
          <Modal.Title>
            Name:- {formData?.name} &nbsp; Post:-{formData?.postApplied} &nbsp;
            Level:- {formData?.level}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                Action <span>*</span>
              </Form.Label>
              <Form.Select
                defaultValue={""}
                value={actionForm?.action}
                onChange={handleFormChange}
                name="action"
                aria-label="Default select example"
              >
                <option>---Please Select one --</option>
                <option value="rejected">Reject</option>
                <option value="hold">Hold</option>
                <option value="shortlisted">Shortlisted</option>
                <option value="interviewed">Called for Interview</option>
                <option value="rescheduled">Rescheduled</option>
                <option value="appointed">Appointed</option>
              </Form.Select>
              <div className="required-field">{formErrors.action}</div>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Remarks <span>*</span>
              </Form.Label>
              <Form.Control
                value={actionForm?.remarks}
                onChange={handleFormChange}
                name="remarks"
                as="textarea"
                rows={3}
              />
              <div className="required-field">{formErrors.remarks}</div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="card p-3 m-3">
        <div className="row">
          <div className="col-2">
            <div className="form-group">
              <label>
                Advertisement <span>*</span>
              </label>
              <select
                name="addId"
                defaultValue={""}
                value={filterData?.addId}
                className="form-control"
                id="addId"
                onChange={onFilter}
              >
                <option value="">-Select-</option>
                {Object.keys(AdvertiseNumber).map((key, index) => {
                  return <option value={key}>{AdvertiseNumber[key]}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>
                Level <span>*</span>
              </label>
              <select
                name="level"
                defaultValue={""}
                value={filterData?.level}
                className="form-control"
                id="level"
                onChange={onFilter}
              >
                <option value="">-Select-</option>
                {Object.keys(level.ALL).map((key, index) => {
                  return <option value={key}>{level.ALL[key]}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>
                Post Applied <span>*</span>
              </label>
              <select
                name="postApplied"
                defaultValue={""}
                value={filterData?.postApplied}
                className="form-control"
                id="postApplied"
                onChange={onFilter}
              >
                <option value="">-Select Post Applied-</option>
                {Object.keys(discipline.ALL).map((key, index) => {
                  return <option value={key}>{discipline.ALL[key]}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>
                Status <span>*</span>
              </label>
              <select
                name="status"
                defaultValue={""}
                value={filterData?.status}
                className="form-control"
                id="status"
                onChange={onFilter}
              >
                <option value="">-Select Status-</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <label>
                Payment Status <span>*</span>
              </label>
              <select
                name="paymentStatus"
                defaultValue={""}
                value={filterData?.paymentStatus}
                className="form-control"
                id="paymentStatus"
                onChange={onFilter}
              >
                <option value="">-Select Payment Status-</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="form-group m-2">
              <button
                type="button"
                onClick={onSearch}
                className="btn btn-primary mt-4"
              >
                <SearchIcon />
              </button>
              <button
                type="button"
                onClick={onClearSearch}
                className="btn btn-primary mt-4"
                style={{ marginLeft: "5px" }}
              >
                <FilterAltOffIcon />
              </button>
              <button
                onClick={downloadCSV}
                type="button"
                className="btn btn-primary mt-4"
                style={{ marginLeft: "5px" }}
              >
                Export
              </button>
              <input
                type="hidden"
                ref={componentRef}
                value={perPage + "," + currentPage}
              />
            </div>
          </div>
        </div>
      </div>
      <DataTable
        title="Applications"
        columns={columns}
        data={getData}
        pagination
        paginationTotalRows={getTotalRow}
        highlightOnHover
        progressPending={loading}
        paginationServer
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handleChangePage}
        paginationRowsPerPageOptions={[10, 20, 50, 100, 1000]}
      />
    </>
  );
};

export default AppList;
